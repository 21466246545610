import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PitchIcon from "../../assets/images/Pitch-icon.png";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import { Modal } from "antd";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormControl, InputLabel, Input } from "@mui/material";
import LeftButton from "../../assets/images/left-button.png";
import RightButton from "../../assets/images/right-button.png";
import Navbar from "../../component/common/NavbarProfile";
import LoadingBackdrop from "../../component/common/Loader";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import BackButton from "../../assets/images/backBtn.svg";
import { useNavigate, useLocation } from "react-router-dom";
import WeekCalendar from "./WeekCalender";

import {
  getAlreaduBookedPitches,
  getCountrytime,
  getHolidaylist,
  getPitchData,
} from "../../handlers/product/api";
import {
  getProducts,
  getProductsBasedOnFilter,
} from "../../handlers/common/api";
import { DateToDays } from "../../utils/DateToDays";
import AllPitches from "./Allpitches";
import Compact from "./Compact";
import dayjs from "dayjs";
import InputAdornment from "@mui/material/InputAdornment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

registerLocale("en-GB", enGB);

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button
    className="border text-center border-gray-300  rounded-lg px-2 h-12 data-picker"
    onClick={onClick}
    ref={ref}
    readOnly
  >
    {value || "Select a date"}
  </button>
));

const PitchSelectionTable = () => {
  const [selectedPitch, setSelectedPitch] = useState(null);
  const [selectedPitchName, setSelectedPitchName] = useState(null);
  const [samplepitch, setSamplePitch] = useState(null);
  const [selectedPitchIcon, setSelectedPitchIcon] = useState(null);
  const [venueData, setVenueData] = useState(null);
  const [bookingOptions, setBookingOptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("day");
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectOpenProduct, setSelectOpenProduct] = useState(false);
  const [selectOpenpitches, setselectOpenpitches] = useState(false);
  const [selectOpenPitch, setSelectOpenPitch] = useState(false);
  const [pitche, setPitches] = useState([]);
  const [sortedpitche, setsortedpitche] = useState([]);
  const [selectedDisplaypitche, setselectedDisplaypitche] =
    useState("All Pitches");
  const [loading, setLoading] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [bookedPitches, setbookedPitches] = useState([]);
  const [pitchWeekAvailability, setPitchWeekAvailability] = useState([]);
  const [selectedBookingOption, setSelectedBookingOption] = useState("");
  const [popupInfo, setPopupInfo] = useState({
    pitch: null,
    time: null,
    date: null,
  });
  const [cartItems, setCartItems] = useState([]);
  const [startPitchIndex, setStartPitchIndex] = useState(0);
  const [visiblePitches, setVisiblePitches] = useState();
  const [holidayList, setHolidayList] = useState();
  const [newTimeWithslots, setNewTimeWithslots] = useState();
  const [newTimeWithslotsWeek, setNewTimeWithslotsWeek] = useState();
  const [serviceItems, setServiceItems] = useState([]);
  const [selectedServiceOptions, setSelectedServiceOptions] = useState();
  const [futuredays, setFuturedays] = useState();
  const [venuedataname, setvenuedataname] = useState();
  const [venuedatabooking, setvenuedatabooking] = useState();
  const [address, setaddress] = useState("");
  const [singaporeTime, setSingaporeTime] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempstate, settempstate] = useState("");
  const [venuename, setvenuename] = useState();
  const [holidaySet, setHolidaySet] = useState(new Set());


  const pitchesPerPage = 6; // number of pitch in slide bar
  const blocksPerPitch = 23; // number of time slot

  const navigate = useNavigate();
  const location = useLocation();

  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const endtoDate = new Date(currentDate);
  endtoDate.setDate(currentDate.getDate() + 6);

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(endtoDate);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [error, setError] = React.useState(null);

  const { venuenamefromurl, event } = useParams();

  const [width, setwidth] = useState(1536);

  let defaultPiche = sortedpitche.map((item) => item.name);

  const CustomInputAdornment = (props) => {
    const { hasError, children, sx, ...other } = props;
    return (
      <InputAdornment {...other}>
        <PriorityHighIcon
          color="error"
          sx={{ marginLeft: 1, opacity: hasError ? 1 : 0 }}
        />
        {children}
      </InputAdornment>
    );
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
    }, 0);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    let previousVenueData = JSON.parse(localStorage.getItem("venueData"));
    if (!previousVenueData) {
      previousVenueData = {};
    }
    if (!previousVenueData?.data) {
      previousVenueData.data = {
        name: venuenamefromurl,
        venue_name: venuename?.venue_name,
        address: venuename?.address,
      };
    }
    localStorage.setItem("venueData", JSON.stringify(previousVenueData));
  }, [venuename]);
  useEffect(() => {
    // const currentUrl = window.location.href;
    const pathSegments = window.location.pathname
      .split("/")
      .filter(Boolean)
      .map((segment) => decodeURIComponent(segment));

    setvenuedataname(pathSegments[2]);

    setvenuedatabooking(pathSegments[4]);

    const data = pitche.map((obj) => obj.name);
    if (!data.includes(selectedDisplaypitche)) {
      if (width >= 1200) {
        setselectedDisplaypitche("All Pitches");
      } else {
        setselectedDisplaypitche("Compact");
      }
    }
  }, [width]);

  const fetchServicesDetails = async (time, weekview, date) => {
    try {
      // console.log("here come");
      console.log(time,weekview,date,"services")
      const times = time.split("-");
      const startTime = times[0].trim();
      const endTime = times[1].trim();


      function calculateDuration(startTime1,endTime1) {
        function formatTime(time) {
          // Regular expression to match and add a space before AM/PM if not present
          return time.replace(/(\d{1,2}:\d{2})(AM|PM)/i, "$1 $2");
        }
        const startTime = formatTime(startTime1);
        const endTime = formatTime(endTime1);
        const convertTo24HourFormat = (time) => {
          let [hours, minutesPeriod] = time.split(":");
          let [minutes, period] = minutesPeriod.split(" ");

          hours = parseInt(hours, 10); // Convert to number for proper comparison
          minutes = parseInt(minutes, 10); // Convert to number for proper comparison

          if (period === "PM" && hours !== 12) {
            hours += 12;
          } else if (period === "AM" && hours === 12) {
            hours = 0;
          }

          // Zero-padding for hours and minutes
          hours = hours.toString().padStart(2, "0");
          minutes = minutes.toString().padStart(2, "0");

          return `${hours}:${minutes}`;
        };

        // console.log("hlo here ");
        // console.log(startTime, endTime, "timing example");

        const startTime24 = convertTo24HourFormat(startTime);
        const endTime24 = convertTo24HourFormat(endTime);

        const startDateTime = new Date(`1970-01-01T${startTime24}:00`);
        let endDateTime = new Date(`1970-01-01T${endTime24}:00`);

        // console.log("hlo here 1");

        if (endDateTime <= startDateTime) {
          endDateTime.setDate(endDateTime.getDate() + 1);
        }

        const duration = (endDateTime - startDateTime) / (1000 * 60);
        return duration;
      }


      // // Calculate the duration in minutes
      // const duration = (endDateTime - startDateTime) / (1000 * 60);
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let dayIndex;
      let isHolidayStatus;

      if (weekview && date) {
        dayIndex = date.getDay();
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        isHolidayStatus = isHoliday(newDate);
      } else {
        dayIndex = selectedDate.getDay();
        isHolidayStatus = isHoliday(selectedDate);
      }
      const day = weekdays[dayIndex];
      const options = {
        method: "POST",
        body: JSON.stringify({
          item_group: "Services",
          booking_for: venuedatabooking,
          slot_start_time: convertTimeFormat(startTime),
          slot_end_time: convertTimeFormat(endTime),
          day_name: day,
          slot_duration_in_minutes: calculateDuration(startTime,endTime),
          is_holiday: isHolidayStatus,
          is_internal: 0,
          for_birthday: selectedPitchName.birthday_event,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log(options,startTime,endTime,"timimg issue")

      console.time("Step 3");
      const response = await fetch(`${getProductsBasedOnFilter}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch product details");
      }
      console.time("Step 4");
      const data = await response.json();
      console.timeEnd("Step 4");
      console.timeEnd("Step 3");

      const arr = [];
      const optionList = data.data;
      // console.log(optionList, "optionlist"Move Sorting and Filtering to Backend: Instead of fetching all data and then filtering and sorting on the client side, you can modify the server API call to handle this logic, e.g., adding query parameters for filtering and sorting.);
      const filterinternal = optionList.filter(
        (item) => item.is_internal === 0
      );
      // console.log(filterinternal, "filter internal");
      for (let i = 0; i < filterinternal?.length; i++) {
        if (filterinternal[i].price !== 0) {
          arr.push({
            value: filterinternal[i].name,
            label:
              filterinternal[i].name + " - " + filterinternal[i].price + "/hr",
            image: filterinternal[i].image,
            price: filterinternal[i].price,
          });
        }
      }
      const sortedArr = arr.slice().sort((a, b) => b.price - a.price);
      setServiceItems(sortedArr);
      setSelectedServiceOptions(sortedArr[0]);
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };


  function isHoliday(date){
    // console.log(holidayList,"list")
    let formattedSelectedDate = date.toISOString().split("T")[0];
    return holidaySet.has(formattedSelectedDate);
  };

  // function isHoliday(date) {
  //   let formattedSelectedDate = date.toISOString().split("T")[0];
  //   console.log(holidayList,"list")
  //   if (holidayList) {
  //     for (let holiday of holidayList) {
  //       console.log(holiday,formattedSelectedDate,"sample")
  //       if (holiday.holiday_date === formattedSelectedDate) {
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // }

  function convertTimeFormat(inputTime) {
    const regex = /(\d{1,2}:\d{2})([APM ]+)/i;
    const matches = inputTime.match(regex);
    const time = matches[1].trim(); // Extracts the time part
    const period = matches[2].trim(); // Extracts the AM/PM part
    let [hours, minutes] = time.split(":");
    // console.log(time, period, "error got");

    if (period.toUpperCase() === "PM" && hours !== "12") {
      hours = parseInt(hours, 10) + 12;
    } else if (period.toUpperCase() === "AM" && hours === "12") {
      hours = "00";
    }

    // console.log("here come ");

    minutes = minutes.padStart(2, "0");

    let formattedTime = `${hours.toString().padStart(2, "0")}:${minutes}:00`;
    if (formattedTime === "00:00:00") {
      formattedTime = "23:59:59";
    }

    return formattedTime;
  }

  function formatDate(date) {
    if (!date) {
      return "";
    }

    if (!(date instanceof Date)) {
      // Attempt to create a Date object from the input
      date = new Date(date);
    }

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Return an empty string if date is invalid
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${months[monthIndex]} ${year}`;
  }

  const buttonNextDisable = useRef();
  const buttonPrevClick = useRef();

  const [clickCount, setClickCount] = useState(0);
  const maxClicks = DateToDays(futuredays);

  useEffect(() => {
    if (clickCount <= 0 && buttonPrevClick.current) {
      buttonPrevClick.current.disabled = true;
    } else if (clickCount >= 0 && buttonPrevClick.current) {
      buttonPrevClick.current.disabled = false;
    }
  }, [clickCount]);

  const prevWeek = () => {
    setLoading(true);
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() - 7);
    setStartDate(newStartDate);
    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() - 7);
    setEndDate(newEndDate);
    setClickCount((prevCount) => prevCount - 1);

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => clearTimeout(timeoutId);
  };

  const nextWeek = () => {
    setLoading(true);
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() + 7);
    setStartDate(newStartDate);
    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() + 7);
    setEndDate(newEndDate);

    setClickCount((prevCount) => prevCount + 1);

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    if (clickCount >= maxClicks && buttonNextDisable.current) {
      buttonNextDisable.current.disabled = true;
    } else if (clickCount <= maxClicks && buttonNextDisable.current) {
      buttonNextDisable.current.disabled = false;
    }
  }, [clickCount]);

  const handleDateChange = (date) => {
    setSelectedDate(date.$d);
  };

  const clearDate = () => {
    setSelectedDate(null);
  };

  const getTiming = (blockIndex) => {
    const startTime = (blockIndex % 24) + 1;
    const endTime = (startTime + 1) % 24 || 12; // For 24:00, display as 12:00 AM
    const startSuffix = startTime >= 12 ? "PM" : "AM";
    const endSuffix = endTime >= 12 ? "PM" : "AM";
    const formattedStartTime = startTime > 12 ? startTime - 12 : startTime;
    const formattedEndTime = endTime > 12 ? endTime - 12 : endTime;
    return `${formattedStartTime}:00 ${startSuffix}-${formattedEndTime}:00 ${endSuffix}`;
  };

  const fetchPitchData = async () => {
    try {
      const pathSegments = window.location.pathname
        .split("/")
        .filter(Boolean)
        .map((segment) => decodeURIComponent(segment));

      const res = await getPitchData(pathSegments[2]);
      setaddress(res.data.address);
      // console.log(res);
      // console.log(res.data.data.venue_name);
      setvenuename(res.data.data);
      const daysofweek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      const pitchWeekAvailability = {};

      const arr = res.data.data.venue_availability_time;
      const maxDate = new Date();

      for (let i = 0; i < arr.length; i++) {
        const day = arr[i].day; // Get the day from the API response

        if (!pitchWeekAvailability[day]) {
          pitchWeekAvailability[day] = [];
        }

        pitchWeekAvailability[day].push({
          start_time: arr[i].start_time,
          end_time: arr[i].end_time,
        });
      }
      setPitchWeekAvailability(pitchWeekAvailability);
    } catch (error) {
      console.log(error);
    }
  };

  function checkIfTimeFitsInRangeoforDay(
    availableRangeStart,
    availableRangeEnd,
    timeToCheck
  ) {
    if (!timeToCheck) {
      return false;
    }
    if (availableRangeEnd.startsWith("23:59")) {
      availableRangeEnd = "24:00:00";
    }

    const convert24HourToMinutes = (time) => {
      let [hours, minutes, seconds] = time.split(":").map(Number);
      return hours * 60 + minutes + seconds / 60;
    };

    // Helper function to convert time in "HH:MM AM/PM" format to minutes since midnight
    const convert12HourToMinutes = (time) => {
      let [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes;
    };

    // Convert available range start and end to minutes since midnight
    const availableStart = convert24HourToMinutes(availableRangeStart);
    const availableEnd = convert24HourToMinutes(availableRangeEnd);

    // Extract and convert start and end times from the time range input to minutes since midnight
    const [startTime, endTime] = timeToCheck.split("-").map((t) => t.trim());
    const startMinutes = convert12HourToMinutes(startTime);
    const endMinutes = convert12HourToMinutes(endTime);

    // Handle case where end time is before start time (crossing midnight)

    const adjustedEndMinutes =
      endMinutes < startMinutes ? endMinutes + 24 * 60 : endMinutes;

    // Check if the start and end times fall within the available range
    const fitsInRange =
      startMinutes >= availableStart && adjustedEndMinutes <= availableEnd;

    return fitsInRange;
  }

  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    return daysOfWeek[dayOfWeekIndex];
  }

  const generateTimeSlots = ({
    date,
    start_time,
    end_time,
    slot_time,
    slots_basis_of,
  }) => {
    // Parse the input times
    const parseTime = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return { hours, minutes, seconds };
    };

    // Format the time in HH:mm AM/PM format
    const formatTime = (date) => {
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const period = hours >= 12 ? "PM" : "AM";
      if (hours === 0) {
        hours = 12;
      } else if (hours > 12) {
        hours -= 12;
      }
      return `${hours}:${minutes}${period}`;
    };

    // Calculate the time increment based on slots_basis_of
    const getIncrement = () => {
      const increment = { minutes: 0, hours: 0 };
      if (slots_basis_of === "Minutes") {
        increment.minutes = parseInt(slot_time, 10);
      } else if (slots_basis_of === "Hourly") {
        increment.hours = parseInt(slot_time, 10);
      }
      return increment;
    };

    const start = parseTime(start_time);
    const end = parseTime(end_time);
    const increment = getIncrement();

    let currentTime = new Date(date);
    currentTime.setHours(start.hours, start.minutes, start.seconds);

    const endTime = new Date(date);
    endTime.setHours(end.hours, end.minutes, end.seconds);

    const timeSlots = [];

    while (currentTime < endTime) {
      const slotStart = new Date(currentTime);
      currentTime.setMinutes(currentTime.getMinutes() + increment.minutes);
      currentTime.setHours(currentTime.getHours() + increment.hours);

      if (currentTime <= endTime) {
        const slotEnd = new Date(currentTime);
        timeSlots.push(`${formatTime(slotStart)}-${formatTime(slotEnd)}`);
      }
    }

    if (endTime.getHours() === 23 && endTime.getMinutes() === 59) {
      const slotStart = new Date(endTime);
      slotStart.setMinutes(slotStart.getMinutes() - increment.minutes + 1);

      // Adjust endTime to represent midnight
      endTime.setHours(0, 0, 0, 0);
      endTime.setDate(endTime.getDate() + 1); // move to the next day for 12:00 AM

      timeSlots.push(`${formatTime(slotStart)}-${formatTime(endTime)}`);
    }

    return timeSlots;
  };

  useEffect(() => {
    // Retrieve venueData from localStorage
    const token = localStorage.getItem("venueData");
    if (token) {
      setVenueData(JSON.parse(token));
    }
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);
  const checkIfMobile = () => {
    setIsMobileView(window.innerWidth <= 768); // set to true if screen width is less than or equal to 768px
  };

  useEffect(() => {
    if (selectedTab === "day") {
      setVisiblePitches(
        pitche.slice(startPitchIndex, startPitchIndex + pitchesPerPage)
      );
    } else {
      setVisiblePitches(pitche); // Set visible pitches to the whole pitche array
    }
  }, [selectedTab, startPitchIndex, pitchesPerPage, pitche, selectedPitch]);

  useEffect(() => {
    fetchPitchData();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch booking options

      const pathSegments = window.location.pathname
        .split("/")
        .filter(Boolean)
        .map((segment) => decodeURIComponent(segment));

      setvenuedataname(pathSegments[2]);
      const bookingResponse = await axios.post(
        "https://devmindmedia.frappe.cloud/api/method/mindmedia.api.booking.api.get_booking_for",
        { venue: pathSegments[2], maintanance_event: 0 }
      );
      const bookingOptions = bookingResponse.data.data || [];
      setBookingOptions(bookingOptions);
      // Fetch pitches
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filterAndGenerateSlots = (data, weekview = 0, date = null) => {
    // Helper function to format time in the required format
    const formatTime = (date) => {
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const period = hours >= 12 ? "PM" : "AM";
      if (hours === 0) {
        hours = 12;
      } else if (hours > 12) {
        hours -= 12;
      }
      return `${hours}:${minutes}${period}`;
    };

    // Helper function to generate 1-hour time slots for the entire day
    const generateFullDaySlots = () => {
      const slots = [];
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);

      for (let i = 0; i < 24; i++) {
        const startTime = new Date(startOfDay);
        startTime.setHours(i);

        const endTime = new Date(startTime);
        endTime.setHours(i + 1);

        slots.push(`${formatTime(startTime)}-${formatTime(endTime)}`);
      }

      return slots;
    };

    // Extract day of the week from the provided date or current date
    const getDayOfWeek = (date) => {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date ? new Date(date).getDay() : new Date().getDay();
      return daysOfWeek[dayIndex];
    };

    const selectedDay = getDayOfWeek(weekview ? date : selectedDate);

    // Filter by booking_for_name
    const filteredByPitchName = data.filter((item) => {
      return item.name === samplepitch;
    });

    if (filteredByPitchName.length === 0) {
      return [];
    }

    const isIceBath = filteredByPitchName[0]?.booking_for_name === "Ice Bath";
    if (isIceBath) {
      // Generate 15-minute slots for the entire day if booking is for "Ice Bath"
      return generateTimeSlots({
        date: selectedDate,
        start_time: "00:00:00",
        end_time: "23:59:59",
        slot_time: "15",
        slots_basis_of: "Minutes",
      });
    }

    // Filter by day of the week
    const filteredByDay = filteredByPitchName[0].slot.filter((slot) => {
      return slot.day === selectedDay;
    });

    if (filteredByDay.length === 0) {
      // Return 1-hour slots for the entire day if no slots match the selected day
      return generateFullDaySlots();
    }

    // Generate time slots for each matching slot
    return filteredByDay.flatMap((slotData) => {
      return generateTimeSlots(slotData);
    });
  };

  // Helper function to parse time string in HH:MM AM/PM format to Date object
  const parseTime = (time) => {
    const [timeStr, period] = time.match(/(\d{1,2}:\d{2})(AM|PM)/).slice(1, 3);
    const [hours, minutes] = timeStr.split(":").map(Number);
    const date = new Date();
    date.setHours(
      period === "PM" && hours !== 12 ? hours + 12 : hours % 12,
      minutes,
      0,
      0
    );
    return date;
  };

  // Helper function to format Date object to HH:MM AM/PM format
  const formatTime = (date) => {
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const period = date.getHours() < 12 ? "AM" : "PM";
    return `${hours}:${minutes}${period}`;
  };

  // Helper function to add minutes to a Date object
  const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  // Function to find gaps in the time slots
  const findTimeGaps = (inputSlots) => {
    const parseTime = (time) => {
      const [hourMin, period] = time
        .match(/(\d{1,2}:\d{2})([AP]M)/)
        .slice(1, 3);
      let [hour, minute] = hourMin.split(":").map(Number);
      if (period === "PM" && hour !== 12) hour += 12;
      if (period === "AM" && hour === 12) hour = 0;
      const date = new Date();
      date.setHours(hour, minute, 0, 0);
      return date;
    };

    const formatTime = (date) => {
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const period = hours >= 12 ? "PM" : "AM";
      if (hours === 0) {
        hours = 12;
      } else if (hours > 12) {
        hours -= 12;
      }
      return `${hours}:${minutes}${period}`;
    };

    const parsedSlots = inputSlots.map((slot) => {
      const [start, end] = slot.split("-");
      return {
        start: parseTime(start),
        end: parseTime(end),
        type: "slot",
      };
    });

    parsedSlots.sort((a, b) => a.start - b.start);

    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(startOfDay);
    endOfDay.setDate(endOfDay.getDate() + 1);

    const gaps = [];
    let lastEnd = startOfDay;

    parsedSlots.forEach((slot) => {
      if (slot.start > lastEnd) {
        const isMidnightGap =
          lastEnd.getHours() === 0 &&
          lastEnd.getMinutes() === 0 &&
          lastEnd.getSeconds() === 0 &&
          slot.start.getHours() === 0 &&
          slot.start.getMinutes() === 0 &&
          slot.start.getSeconds() === 0 &&
          lastEnd.toDateString() !== slot.start.toDateString();

        if (!isMidnightGap) {
          gaps.push({ start: lastEnd, end: slot.start, type: "gap" });
        }
      }
      lastEnd = slot.end;
    });

    if (lastEnd < endOfDay) {
      gaps.push({ start: lastEnd, end: endOfDay, type: "gap" });
    }

    const combined = [...parsedSlots, ...gaps];
    combined.sort((a, b) => a.start - b.start);

    const combinedRanges = combined.map((entry) => ({
      range: `${formatTime(entry.start)}-${formatTime(entry.end)}`,
      type: entry.type,
    }));

    for (let i = 0; i < combinedRanges.length; i++) {
      if (
        combinedRanges[i].range === "11:45PM-12:00AM" &&
        combinedRanges[i].type === "gap"
      ) {
        combinedRanges[i].type = "slot";
      }
    }

    return combinedRanges;
  };

  useEffect(() => {
    let data;
    let weekData = [];

    if (selectedTab === "day") {
      data = filterAndGenerateSlots(bookingOptions);

      const gapRanges = findTimeGaps(data).filter(
        (item) => item.range !== "12:00AM-12:00AM"
      );
      setNewTimeWithslots(gapRanges);
    } else {
      for (let i = 0; i < 7; i++) {
        const currentDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + i
        );
        const formattedDate = formatDateforGetAlreadyBookedPitches(currentDate);

        // Temporarily override the selectedDate for the function call

        const dayData = filterAndGenerateSlots(
          bookingOptions,
          1,
          formattedDate
        );
        // console.log(dayData, "timeday");
        weekData.push({ date: formattedDate, slots: dayData });

        // Restore the original selectedDate
      }

      console.log(weekData, "weekData");
      const gapRangesForWeek = weekData.map((day) => {
        return {
          date: day.date,
          gaps: findTimeGaps(day.slots).filter(
            (item) => item.range !== "12:00AM-12:00AM"
          ),
        };
      });

      setNewTimeWithslotsWeek(gapRangesForWeek);
    }
  }, [bookingOptions, selectedDate, startDate, selectedTab]);

  function formatDateforGetAlreadyBookedPitches(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function formateTime(timeRange) {
    // Helper function to add leading zero to the hour if necessary
    function formatTime(time) {
      let [hour, minutePeriod] = time.split(":");
      let [minute, period] = minutePeriod.split(" ");
      hour = parseInt(hour, 10);

      // Format hour with leading zero if necessary
      let formattedHour = hour.toString().padStart(2, "0");
      let formattedMinute = minute.padStart(2, "0");

      return `${formattedHour}:${formattedMinute} ${period}`;
    }

    // Split the time range into start and end times
    let [startTime, endTime] = timeRange.split("-");

    // Format both start and end times
    let formattedStartTime = formatTime(startTime.trim());
    let formattedEndTime = formatTime(endTime.trim());

    return `${formattedStartTime}-${formattedEndTime}`;
  }

  function getNextDate(dateString) {
    const currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() + 1); // Adding one day
    return currentDate.toISOString().split("T")[0]; // Convert to ISO date string (YYYY-MM-DD)
  }

  function doesNameAppearInPitches(selectedName) {
    // Iterate over the array of objects
    for (let i = 0; i < bookingOptions?.length; i++) {
      // Check if the current object has a matching "get_pitches_from_this_booking_for" value

      if (
        bookingOptions[i].get_pitches_from_this_booking_for === selectedName
      ) {
        return true; // Return true if a match is found
      }
    }
    return false; // Return false if no matches are found
  }

  const fetchAlreadyBookedPitches = async (selectedPitch) => {
    try {
      const pathSegments = window.location.pathname
        .split("/")
        .filter(Boolean)
        .map((segment) => decodeURIComponent(segment));
      setvenuedataname(pathSegments[2]);
      let payload = {};
      if (selectedTab === "day") {
        payload = {
          venue: pathSegments[2],
          booking_for: selectedPitch,
          start_date: formatDateforGetAlreadyBookedPitches(selectedDate),
          end_date: getNextDate(
            formatDateforGetAlreadyBookedPitches(selectedDate)
          ),
        };
      } else {
        payload = {
          venue: pathSegments[2],
          booking_for: selectedPitch,
          start_date: formatDateforGetAlreadyBookedPitches(startDate),
          end_date: formatDateforGetAlreadyBookedPitches(endDate),
        };
      }

      let booking_for_object = bookingOptions?.filter(
        (item) => item.name === selectedPitch
      );
      if (
        booking_for_object[0]?.get_pitches_from_this_booking_for ||
        doesNameAppearInPitches(booking_for_object[0]?.name) ||
        booking_for_object[0]?.consecutive_pitch
      ) {
        delete payload["booking_for"];
      }

      const res = await getAlreaduBookedPitches(payload);

      setbookedPitches(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const getHolidays = async (selectedPitch) => {
    try {
      if (venueData) {
        let payload = { booking_for: venuedatabooking };

        const res = await getHolidaylist(payload);
        setHolidayList(res?.data?.data?.holidays);
        const holidayDatesSet = new Set(res?.data?.data?.holidays.map(holiday => holiday.holiday_date));
        setHolidaySet(holidayDatesSet);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHolidays();
  }, [venueData]);

  useEffect(() => {
    if (selectedPitch) {
      fetchAlreadyBookedPitches(selectedPitch);
    } else {
      const pathSegments = window.location.pathname
        .split("/")
        .filter(Boolean)
        .map((segment) => decodeURIComponent(segment));
      setvenuedataname(pathSegments[4]);
      fetchAlreadyBookedPitches(pathSegments[2]);
    }
  }, [
    selectedPitch,
    selectedDate,
    startDate,
    selectedTab,
    endDate,
    bookingOptions,
  ]);

  useEffect(() => {
    const fetchData2 = async () => {
      if (selectedPitch && venueData) {
        const fields = encodeURIComponent(
          '["name","pitch_name","booking_allowed_after_hrs"]'
        );
        const venueFilter = encodeURIComponent(
          `["venue", "=", "${venuedataname}"]`
        );

        // football

        let booking_for_Filter = encodeURIComponent(
          `["booking_for", "=", "${venuedatabooking}"]`
        );

        const stringAfterFirstHyphen = venuedatabooking
          .split("-")
          .slice(1)
          .join("-");

        let booking_for_object = bookingOptions?.filter(
          (item) => item.name === venuedatabooking
        );

        // if (
        //   booking_for_object[0].consecutive_pitch ||
        //   stringAfterFirstHyphen.toLowerCase().includes("birthday")
        // ) {
        //   bookingOptions.filter((item) => {
        //     return item.booking_for_name.toLowerCase().includes("footb");
        //   });
        //   const findFootball = bookingOptions.filter((item) => {
        //     return item.booking_for_name.toLowerCase().includes("footb");
        //   });

        //   if (findFootball) {
        //     booking_for_Filter = encodeURIComponent(
        //       `["booking_for", "=", "${findFootball[0]?.name}"]`
        //     );
        //   }
        // }
        let temp = booking_for_object[0]?.get_pitches_from_this_booking_for
          ? booking_for_object[0]?.get_pitches_from_this_booking_for
          : venuedatabooking;
        booking_for_Filter = encodeURIComponent(
          `["booking_for", "=", "${temp}"]`
        );

        const filters = `[${venueFilter},${booking_for_Filter}]`;
        const limit_page_length = encodeURIComponent("1000");

        const url = `https://devmindmedia.frappe.cloud/api/resource/Pitches?fields=${fields}&filters=${filters}&limit_page_length=${limit_page_length}`;
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
          const fetchedPitches = data.data || [];
          setPitches(fetchedPitches);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData2();
  }, [selectedPitch, venueData, bookingOptions]);

  useEffect(() => {
    fetchData();
  }, [venueData]);

  const handlePitchChange = (pitch) => {
    setSelectedPitch(pitch);
    setvenuedatabooking(pitch);
    setSelectOpen(true);
  };

  const handleSelectOpen = () => {
    // if (cartItems.length > 0) {
    //   setIsModalVisible(true);
    // }
    // else {
    setSelectOpen(true);

    // }
  };

  const handleselectmodel = () => {
    setCartItems([]);
    setIsModalVisible(false);
    setSelectedPitch(tempstate);
    setvenuedatabooking(tempstate);

    const parts = tempstate.split("-");
    // const stringAfterHyphen = parts.slice(1).join("-");

    let booking_for_object = bookingOptions?.filter(
      (item) => item.name === tempstate
    );

    setSelectedPitchName(booking_for_object[0]);
    setSamplePitch(tempstate);
    setSelectOpen(false);
    fetchData();
  };
  const handleSelectOpenProduct = () => {
    setSelectOpenProduct(!selectOpenProduct);
  };

  const handleSelectOpenPitches = () => {
    setselectOpenpitches(!selectOpenpitches);
  };

  const handleSelectOpenPitch = () => {
    setSelectOpenPitch(!selectOpenPitch);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  useEffect(() => {
    const maxDate = new Date();
    maxDate.setDate(
      maxDate.getDate() + selectedPitchName?.days_availability_for_calendar_view
    );
    console.log("future days", selectedPitchName);
    setFuturedays(maxDate);
  }, [selectedPitchName]);

  useEffect(() => {
    handleSelectChange(event);
    setSelectedPitch(event);
    setvenuedatabooking(event);
  }, [event]);

  useEffect(() => {
    let booking_for_object = bookingOptions?.filter(
      (item) => item.name === venuedatabooking
    );
    setSelectedPitchName(booking_for_object[0]);
  }, [venuedatabooking, bookingOptions]);

  const handleSelectChange = (event) => {
    if (cartItems.length > 0) {
      if (event?.target?.value) {
        settempstate(event?.target.value);
      }

      setIsModalVisible(true);
    } else {
      let event2;
      if (event?.target?.value) {
        event2 = event.target.value;
      } else {
        event2 = event;
      }

      if (event2 !== selectedPitch) {
        // Clear the cart and update the selected pitch
        if (selectedPitch) {
          setCartItems([]);
          localStorage.removeItem("cartItems");
        }
        setSelectedPitch(event2);
        setvenuedatabooking(event2);
        const parts = event2.split("-");
        const stringAfterHyphen = parts.slice(1).join("-");

        let booking_for_object = bookingOptions?.filter(
          (item) => item.name === event2
        );
        setSelectedPitchName(booking_for_object[0]);

        setSamplePitch(event2);
        setSelectOpen(false); // Close the dropdown after selecting an item
        fetchData();
      } else {
        // If the newly selected pitch is the same as the current pitch, just update the selected pitch
        setSelectedPitch(event2);
        const parts = event2.split("-");
        const stringAfterHyphen = parts.slice(1).join("-");
        let booking_for_object = bookingOptions?.filter(
          (item) => item.name === event2
        );
        setSelectedPitchName(booking_for_object[0]);
        setSamplePitch(event2);
        setSelectOpen(false); // Close the dropdown after selecting an item
        fetchData();
      }
      setSelectedBookingOption(event2);
    }
  };

  const handlePitchSelectChange = (event) => {
    setselectedDisplaypitche(event.target.value);
  };

  const handlePitchSelectChange2 = () => {
    if (selectedDisplaypitche === "All Pitches") {
      if (selectedTab === "day") {
        setVisiblePitches(
          pitche.slice(startPitchIndex, startPitchIndex + pitchesPerPage)
        );
      } else {
        setVisiblePitches(pitche);
      }
    } else if (selectedDisplaypitche === "Compact") {
      if (selectedTab === "day") {
        setVisiblePitches(pitche);
      } else {
        setVisiblePitches(pitche);
      }
    }
  };

  const handlePitchSelectChange3 = () => {
    setselectedDisplaypitche(width < 1200 ? "Compact" : "All Pitches");
  };

  useEffect(() => {
    handlePitchSelectChange3();
  }, [selectedPitch]);
  useEffect(() => {
    handlePitchSelectChange2();
  }, [
    startPitchIndex,
    selectedDisplaypitche,
    selectedTab,
    pitche,
    selectedPitch,
  ]);

  useEffect(() => {
    setVenueData((prevData) => ({ ...prevData, booking_for: selectedPitch }));
  }, [selectedPitch]);

  function parseTimeRange(timeRange) {
    let [startTime, endTime] = timeRange.split("-").map((time) => {
      const [timeString, modifier] = time.trim().split(" ");
      let [hours, minutes] = timeString.split(":").map(Number);
      if (modifier === "PM" && hours !== 12) hours += 12;
      if (modifier === "AM" && hours === 12) hours = 0;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:00`;
    });
    // if(endTime === "00:00:00"){
    //   endTime = "23:59:59"
    // }
    return { startTime, endTime };
  }
  function filterBookedPitches(bookedPitches, startTime, endTime) {
    return bookedPitches.filter((booking) => {
      return booking.starts_on === startTime && booking.ends_on === endTime;
    });
  }

  useEffect(() => {
    const icon = bookingOptions.filter((item) => item.name === selectedPitch);
    setSelectedPitchIcon(icon[0]?.icon_image ? icon[0]?.icon_image : PitchIcon);
  }, [selectedPitch, bookingOptions]);

  useEffect(() => {
    if (venueData) {
      localStorage.setItem("venueData", JSON.stringify(venueData));
    }
  }, [venueData]);

  const handleBoxClick = (pitch, time, weekview, date) => {
    console.log("hlo time issue");
    // console.log(pitch, time, weekview, date, "week view issue");
    console.time("Step 1");
    const isSlotBooked = cartItems.some((bookedSlot) => {
      return (
        bookedSlot.name === pitch.name &&
        bookedSlot.time === time &&
        bookedSlot.date ===
          (weekview === true ? formatDate(date) : formatDate(selectedDate))
      );
    });
    console.timeEnd("Step 1");

    if (isSlotBooked) {
      toast.warn("This slot is Already Booked");
    } else {
      // If slot is not booked, proceed with handling the click as before
      console.time("Step 2");
      fetchServicesDetails(time, weekview, date).then(() => {
        console.timeEnd("Step 2");
        
        setShowPopup(true);
       
        const { startTime, endTime } = parseTimeRange(time);

        let formattedSelectedDate = date.toISOString().split("T")[0];
        let startDateTime = `${formattedSelectedDate} ${startTime}`;
        let endDateTime = `${formattedSelectedDate} ${endTime}`;

        
        const filteredBookings = filterBookedPitches(
          bookedPitches,
          startDateTime,
          endDateTime
        );

        // console.log("harshit 2");

        let filteredPitches = pitche.filter((pitch) => {
          return !filteredBookings.some(
            (filteredPitch) => filteredPitch.custom_pitch === pitch.name
          );
        });

        if (selectedPitchName?.consecutive_pitch) {
          // Main Part-----------------------------------------------
          console.log("issue here");
          let bookedPitchesArray = [];
          // console.log("here this issue");
          // console.log(pitche, pitche.length, filteredBookings);

          for (let i = 0; i < pitche.length; i = i + 3) {
            const pitch1 = pitche[i];
            const pitch2 = i + 1 < pitche.length ? pitche[i + 1] : null;
            const pitch3 = i + 2 < pitche.length ? pitche[i + 2] : null;

            if (
              filteredBookings.some(
                (booked) => booked.custom_pitch === pitch1?.name
              ) ||
              (pitch2 &&
                filteredBookings.some(
                  (booked) => booked.custom_pitch === pitch2.name
                )) ||
              (pitch3 &&
                filteredBookings.some(
                  (booked) => booked.custom_pitch === pitch3.name
                ))
            ) {
              console.log("continue");
              continue;
            } else {
              console.log("here this issue 1");
              bookedPitchesArray.push(pitch1);
            }
          }

          console.log("here this issue 2");
          // let now = new Date();
          console.log(
            "harshit 3",
            time,
            pitch.booking_allowed_after_hrs,
            weekview,
            date
          );
          const filteredPitches = bookedPitchesArray.filter(
            (pitch) =>
              !isSlotDisabledByTodayTime(
                time,
                pitch.booking_allowed_after_hrs,
                weekview,
                date
              )
          );

          //--------------------------------------------------------------------------
          setsortedpitche(filteredPitches);

          function formatTimeRange(inputTime) {
            // Add a space before "AM" or "PM" if it's missing
            const formattedTime = inputTime.replace(/([0-9])(AM|PM)/g, "$1 $2"); // Add space after time and before AM/PM
            // .replace(/(\d{1,2}:\d{2} [APM]+)-(\d{1,2}:\d{2} [APM]+)/g, '$1 - $2');  // Ensure there's space around the dash

            return formattedTime;
          }

          const updatetime = formatTimeRange(time);
          console.log(time, updatetime, "popup set");

          console.log("here 6");
          setPopupInfo({
            pitch: pitch || filteredPitches[0],
            time: updatetime,
            date:
              weekview === true ? formatDate(date) : formatDate(selectedDate),
          });
        } else {
          console.log("error get ", filteredPitches);
          console.log(weekview, date, time, "error");
          const bookedPitchesArray = filteredPitches.filter(
            (pitch) =>
              !isSlotDisabledByTodayTime(
                time,
                pitch.booking_allowed_after_hrs,
                weekview,
                date
              )
          );

          console.log("harshit 4", time, date);

          setsortedpitche(bookedPitchesArray);
          // setsortedpitche(filteredPitches)

          console.log("harshit 5");
          console.log("here 7");

          function formatTimeRange(inputTime) {
            // Add a space before "AM" or "PM" if it's missing
            const formattedTime = inputTime.replace(/([0-9])(AM|PM)/g, "$1 $2"); // Add space after time and before AM/PM
            // .replace(/(\d{1,2}:\d{2} [APM]+)-(\d{1,2}:\d{2} [APM]+)/g, '$1 - $2');  // Ensure there's space around the dash

            return formattedTime;
          }

          const updatetime = formatTimeRange(time);
          console.log(time, updatetime, "popup set");

          setPopupInfo({
            pitch: pitch || bookedPitchesArray[0],
            time: updatetime,
            date:
              weekview === true ? formatDate(date) : formatDate(selectedDate),
          });
        }
      });
    }
  };

  function convertDateFormat(dateStr) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [day, month, year] = dateStr.split(" ");
    const monthNumber = monthNames.indexOf(month) + 1;
    const formattedMonth = monthNumber.toString().padStart(2, "0");
    const formattedDay = day.padStart(2, "0");
    const formattedDate = `${year}/${formattedMonth}/${formattedDay}`;

    return formattedDate;
  }

  function getMinutesInRange(time) {
    console.log(time, "time calculate");
    const times = time.split("-");

    function formatTime(time) {
      // Regular expression to match and add a space before AM/PM if not present
      return time.replace(/(\d{1,2}:\d{2})(AM|PM)/i, "$1 $2");
    }
    const startTime = formatTime(times[0].trim());
    const endTime = formatTime(times[1].trim());

    console.log("hlo here 4");

    // Convert time to 24-hour format for easier calculation
    const convertTo24HourFormat = (time) => {
      let [hours, minutesPeriod] = time.split(":");
      let [minutes, period] = minutesPeriod.split(" ");

      hours = parseInt(hours, 10); // Convert to number for proper comparison
      minutes = parseInt(minutes, 10); // Convert to number for proper comparison

      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }

      // Zero-padding for hours and minutes
      hours = hours.toString().padStart(2, "0");
      minutes = minutes.toString().padStart(2, "0");

      return `${hours}:${minutes}`;
    };

    console.log("hlo here ");
    console.log(startTime, endTime, "timing example");

    const startTime24 = convertTo24HourFormat(startTime);
    const endTime24 = convertTo24HourFormat(endTime);

    const startDateTime = new Date(`1970-01-01T${startTime24}:00`);
    let endDateTime = new Date(`1970-01-01T${endTime24}:00`);

    console.log("hlo here 1");

    if (endDateTime <= startDateTime) {
      endDateTime.setDate(endDateTime.getDate() + 1);
    }

    console.log("hlo here 2");

    // Calculate the duration in minutes
    const duration = (endDateTime - startDateTime) / (1000 * 60);
    return duration;
  }

  const calculateTimeLeft = useCallback(() => {
    const endTime = localStorage.getItem("countdownEndTime");
    if (!endTime) {
      return "not there";
    }
    const now = new Date().getTime();
    const timeRemaining = Math.max((endTime - now) / 1000, 0);
    return timeRemaining;
  }, []);

  // Set up the initial end time or retrieve from local storage
  useEffect(() => {
    const savedEndTime = localStorage.getItem("countdownEndTime");
    if (!savedEndTime) {
      const endTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes from now
      localStorage.setItem("countdownEndTime2", endTime);
    }
    setTimeLeft(calculateTimeLeft());
  }, [calculateTimeLeft]);

  // Update the countdown every second
  useEffect(() => {
    if (isRunning) {
      const intervalId = setInterval(() => {
        const timeRemaining = calculateTimeLeft();
        setTimeLeft(timeRemaining);
        if (timeRemaining === "not there") {
          return;
        }
        if (timeRemaining <= 0) {
          clearInterval(intervalId);
          localStorage.removeItem("countdownEndTime");
          // localStorage.removeItem("cartItems");
          setCartItems([]);

          setIsRunning(false);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isRunning, calculateTimeLeft]);

  const startTimer = (restart = true) => {
    if (restart) {
      const endTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes from now
      localStorage.setItem("countdownEndTime2", endTime);
    }
    setTimeLeft(calculateTimeLeft());
    setIsRunning(true);
  };

  useEffect(() => {
    const savedEndTime = localStorage.getItem("countdownEndTime");

    if (savedEndTime) {
      setTimeLeft(calculateTimeLeft());
      setIsRunning(true);
    }
  }, [calculateTimeLeft]);
  // Format the time left as MM:SS
  const formatTimeForCountDown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (
      storedCartItems?.length === 0 ||
      storedCartItems === undefined ||
      storedCartItems === null
    ) {
      localStorage.removeItem("countdownEndTime");
    } else {
      calculateTimeLeft();
    }
    if (cartItems.length > 0) {
      startTimer(false);
    }
  }, []);

  const handleAdd = () => {
    console.log(popupInfo.time, "time handle add");
    if (cartItems.length === 0) {
      startTimer();
    }

    const code = serviceItems.find(
      (item) => item?.label === selectedServiceOptions
    );
    if (selectedPitchName?.consecutive_pitch) {
      const no = pitche.findIndex(
        (pitch) => pitch.name === popupInfo.pitch.name
      );

      if (
        no < pitche.length - 2 &&
        !isSlotSelectedInCart(pitche[no + 1], popupInfo.time) &&
        !isSlotSelectedInCart(pitche[no + 2], popupInfo.time) &&
        !isSlotAlreadyBooked(pitche[no + 1], popupInfo.time) &&
        !isSlotAlreadyBooked(pitche[no + 2], popupInfo.time)
      ) {
        const newItem = [
          {
            pitch: popupInfo.pitch.name,
            pitch_name: popupInfo.pitch.pitch_name,
            time: popupInfo.time,
            date: popupInfo.date,
            minutes: getMinutesInRange(popupInfo.time),
            item_code: code ? code.value : selectedServiceOptions.value,
            rate: code ? code.price : selectedServiceOptions.price,
            item_category: "Services",
            isnext: false,
            name: `${venuedataname} | ${popupInfo.pitch.pitch_name} | ${selectedPitchName.booking_for_name}`,
          },
          {
            pitch: pitche[no + 1].name,
            pitch_name: pitche[no + 1].pitch_name,

            time: popupInfo.time,
            date: popupInfo.date,
            isnext: true,
            minutes: getMinutesInRange(popupInfo.time),
            item_code: code ? code.value : selectedServiceOptions.value,
            rate: code ? code.price : selectedServiceOptions.price,
            item_category: "Services",
            name: `${venuedataname} | ${pitche[no + 1].pitch_name} | ${
              selectedPitchName.booking_for_name
            }`,
          },
          {
            pitch: pitche[no + 2].name,
            pitch_name: pitche[no + 2].pitch_name,
            time: popupInfo.time,
            date: popupInfo.date,
            isnext: true,
            minutes: getMinutesInRange(popupInfo.time),
            item_code: code ? code.value : selectedServiceOptions.value,
            rate: code ? code.price : selectedServiceOptions.price,
            item_category: "Services",
            name: `${venuedataname} | ${pitche[no + 2].pitch_name} | ${
              selectedPitchName.booking_for_name
            }`,
          },
        ];

        const newCartItems = [...cartItems, ...newItem];
        updateCartItems(newCartItems);
        // }
      } else {
        toast.error("8a pitch not available starting from this pitch");
      }
    } else {
      const newItem = [
        {
          pitch: popupInfo.pitch.name,
          pitch_name: popupInfo.pitch.pitch_name,
          time: popupInfo.time,
          date: popupInfo.date,
          minutes: getMinutesInRange(popupInfo.time),
          item_code: code ? code.value : selectedServiceOptions.value,
          rate: code ? code.price : selectedServiceOptions.price,
          item_category: "Services",
          name: `${venuedataname} | ${popupInfo.pitch.pitch_name} | ${selectedPitchName.booking_for_name}`,
        },
      ];

      const newCartItems = [...cartItems, ...newItem];
      updateCartItems(newCartItems);
    }

    setShowPopup(false);

    setPopupInfo({ pitch: null, time: null, date: null });
  };

  const removeFromCart = (name, time, date) => {
    if (selectedPitchName?.consecutive_pitch) {
      const no = pitche.findIndex((pitch) => pitch.name === name);

      let updatedCartItems = cartItems.filter(
        (item) =>
          item.pitch !== pitche[no].name ||
          item.time !== time ||
          item.date !== date
      );
      updatedCartItems = updatedCartItems.filter(
        (item) =>
          item.pitch !== pitche[no + 1].name ||
          item.time !== time ||
          item.date !== date
      );
      updatedCartItems = updatedCartItems.filter(
        (item) =>
          item.pitch !== pitche[no + 2].name ||
          item.time !== time ||
          item.date !== date
      );

      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    } else {
      const updatedCartItems = cartItems.filter(
        (item) =>
          item.pitch !== name || item.time !== time || item.date !== date
      );
      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    }
  };

  const isSlotSelectedInCart = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    if (!pitch) return true;
    let temp = cartItems.some((item) => {
      const isSamePitch = item.pitch === pitch.name;
      const isSameDate =
        item.date ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      // console.log("here");
      const isTimeOverlap = isTimeInRange(time, item.time);
      return isSamePitch && isSameDate && isTimeOverlap;
    });
    if (weekview && temp) {
    }
    return temp;
  };

  const isSlotAlreadyBooked = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    if (!pitch) return false;
    let temp = bookedPitches.some((item) => {
      const isSamePitch = item.custom_pitch === pitch.name;

      const isSameDate =
        formatDate(item.starts_on.split(" ")[0]) ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      const isTimeOverlap = isTimeInRange(
        time,
        formatTimeRange(item.starts_on, item.ends_on)
      );
      return isSamePitch && isSameDate && isTimeOverlap;
    });
    if (weekview && temp) {
    }
    return temp;
  };

  const isSlotDeleatable = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    //if (!pitch) return true;
    //if (!selectedPitchName.substring(0, 2).toLowerCase() === "8a") return true;

    let temp = cartItems.some((item) => {
      const isSamePitch = item.pitch === pitch.name;
      const isSameDate =
        item.date ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      const isTimeOverlap = isTimeInRange(time, item.time);
      const isdeletable = !item.isnext;
      return isSamePitch && isSameDate && isTimeOverlap && isdeletable;
    });

    return temp;
  };

  function formatTimeRange(startTime, endTime) {
    // Convert start time to 12-hour format
    let startParts = startTime.split(" ")[1].split(":");
    let startHour = parseInt(startParts[0]);
    let startMinutes = startParts[1];
    let startPeriod = startHour >= 12 ? "PM" : "AM";
    startHour = startHour % 12 || 12; // Convert 0 to 12 for 12-hour clock

    // Convert end time to 12-hour format
    let endParts = endTime.split(" ")[1].split(":");
    let endHour = parseInt(endParts[0]);
    let endMinutes = endParts[1];
    let endPeriod = endHour >= 12 ? "PM" : "AM";
    endHour = endHour % 12 || 12; // Convert 0 to 12 for 12-hour clock

    // Construct the formatted time range string
    let formattedStartTime = `${startHour}:${startMinutes} ${startPeriod}`;
    let formattedEndTime = `${endHour}:${endMinutes} ${endPeriod}`;
    let timeRange = `${formattedStartTime}-${formattedEndTime}`;

    return timeRange;
  }

  const converthhmmssTimeToAMPM = (timeString) => {
    // Parse time string into hours, minutes, and seconds
    const [hoursStr, minutesStr] = timeString
      .split(":")
      .map((str) => parseInt(str, 10));

    // Determine if it's AM or PM
    const period = hoursStr < 12 ? "AM" : "PM";

    // Convert to 12-hour format
    const hours12 = hoursStr % 12 || 12; // Handle 12 as 12 for 12-hour format
    const minutes = minutesStr.toString().padStart(2, "0");

    // Format the time into HH:mm AM/PM format
    const time12 = `${hours12}:${minutes} ${period}`;

    return time12;
  };

  const addMinutesToTimeAndSave = (timeString, minutesToAdd) => {
    // Parse time string into hours, minutes, and seconds
    const [hoursStr, minutesStr, secondsStr] = timeString
      .split(":")
      .map((str) => parseInt(str, 10));

    // Convert to milliseconds
    const timeInMilliseconds =
      (hoursStr * 60 * 60 + minutesStr * 60 + secondsStr) * 1000;

    // Add minutes
    const newTimeInMilliseconds = timeInMilliseconds + minutesToAdd * 60 * 1000;

    // Create a new Date object with the updated time
    const newDate = new Date(newTimeInMilliseconds);

    // Format the time into HH:mm:ss format
    const hours = newDate.getHours().toString().padStart(2, "0");
    const minutes = newDate.getMinutes().toString().padStart(2, "0");
    const seconds = newDate.getSeconds().toString().padStart(2, "0");

    const newTime = `${hours}:${minutes}:${seconds}`;

    // Example usage of saving the new time
  };

  // Function to check if a given time is within the range of another time range
  const isTimeInRange = (time, range) => {
    if (!range) return false;
    // Parse the time values as hours, minutes, and meridiem (AM/PM)
    const timeMatch = time.match(/(\d+):(\d+) (AM|PM)/);

    if (timeMatch === null) {
      return false;
    }

    // console.log(time, range, "data input")

    const [selectedStartHour, selectedStartMinute, selectedStartMeridiem] =
      timeMatch.slice(1);
    const [slotStartHour, slotStartMinute, slotStartMeridiem] = range
      .split("-")[0]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);
    const [slotEndHour, slotEndMinute, slotEndMeridiem] = range
      .split("-")[1]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);

    // Convert hours to 24-hour format if necessary
    const convertTo24Hour = (hour, meridiem) => {
      if (hour === "12") {
        return meridiem === "AM" ? 0 : 12;
      }
      return parseInt(hour) + (meridiem === "PM" ? 12 : 0);
    };

    const selectedHour = convertTo24Hour(
      selectedStartHour,
      selectedStartMeridiem
    );
    const slotStart = convertTo24Hour(slotStartHour, slotStartMeridiem);
    const slotEnd = convertTo24Hour(slotEndHour, slotEndMeridiem);

    // Convert minutes to integers
    const selectedMinute = parseInt(selectedStartMinute);
    const slotStartMinuteInt = parseInt(slotStartMinute);
    const slotEndMinuteInt = parseInt(slotEndMinute);

    // Calculate the total minutes for the selected time
    const selectedTotalMinutes = selectedHour * 60 + selectedMinute;

    // Check if the selected time falls within the range
    if (slotStart <= slotEnd) {
      return (
        selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
        selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt
      );
    } else {
      // If the range spans across AM/PM, consider it as two separate ranges
      return (
        (selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
          selectedTotalMinutes < 24 * 60) ||
        (selectedTotalMinutes >= 0 &&
          selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt)
      );
    }
  };

  const updateCartItems = (newCartItems) => {
    setCartItems(newCartItems);
    localStorage.setItem("cartItems", JSON.stringify(newCartItems));
  };

  const uniqueItems = useMemo(() => {
    return cartItems.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.name === item.name && t.date === item.date && t.time === item.time
        )
    );
  }, [cartItems]);

  useEffect(() => {
    // Retrieve cartItems from localStorage on component mount
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    console.log("here 9");

    setPopupInfo({ pitch: null, time: null, date: null });
  };
  function formatDateToDayMonth(dateString) {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Get the day of the month
    const day = date.getDate();

    // Get the month name
    const month = date.toLocaleString("default", { month: "short" });

    // Return the formatted string
    return `${day} ${month}`;
  }

  const handlePrevButtonClick = () => {
    if (startPitchIndex > 0) {
      setLoading(true);
      setStartPitchIndex(startPitchIndex - 1);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 50);

    return () => clearTimeout(timeoutId);
  };

  const handleNextButtonClick = () => {
    if (startPitchIndex + pitchesPerPage < pitche.length) {
      setLoading(true);

      setStartPitchIndex(startPitchIndex + 1);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 50);

    return () => clearTimeout(timeoutId);
  };
  const formatTimeRangespace = (timeRange) => {
    if (timeRange) {
      const insertSpace = (time) => {
        return time.replace(/([AP]M)/, " $1");
      };

      const [start, end] = timeRange.split("-");

      const formattedStart = insertSpace(start);

      const formattedEnd = insertSpace(end);

      return `${formattedStart}-${formattedEnd}`;
    } else {
      return;
    }
  };

  function convertTo24HourFormattest(time12Hour) {
    console.log(time12Hour, "inputharsh");
    const [startTime, endTime] = time12Hour.split("-");

    function convertTime(time) {
      // let [timePart, period] = time.split(" ");

      const regex = /(\d{1,2}:\d{2})([APM ]+)/i;
      const matches = time.match(regex);
      const timePart = matches[1].trim(); // Extracts the time part
      const period = matches[2].trim();

      let [hour, minute] = timePart.split(":").map(Number);

      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }

      hour = hour < 10 ? `0${hour}` : hour;
      minute = minute < 10 ? `0${minute}` : minute;

      return `${hour}:${minute}`;
    }

    const startTime24 = convertTime(startTime);
    const endTime24 = convertTime(endTime);

    return `${startTime24}-${endTime24}`;
  }

  const formatTimeRangeView = (timeRange) => {
    if (timeRange) {
      const formatTime = (time) => {
        let [hours, minutes] = time.split(" ")[0].split(":");
        const period = time.split(" ")[1];

        if (period === "PM" && parseInt(hours) !== 12) {
          hours = (parseInt(hours) + 12).toString();
        } else if (period === "AM" && parseInt(hours) === 12) {
          hours = "00";
        }

        // Ensure hours and minutes are always 2 digits
        hours = hours.padStart(2, "0");
        minutes = minutes.padStart(2, "0");

        return `${hours}:${minutes}`;
      };

      const [start, end] = timeRange.split("-");

      const formattedStart = formatTime(start);
      const formattedEnd =
        formatTime(end) === "00:00" ? "24:00" : formatTime(end);

      return `${formattedStart}-${formattedEnd}`;
    } else {
      return;
    }
  };

  const getTimeRange = (time) => {
    const [start, end] = formatTimeRangeView(formatTimeRangespace(time)).split(
      "-"
    );
    return (
      <p className="my-4 text-center text-xs sm:text-sm md:text-base  md:px-6 sm:px-8 md:mx-10 w-auto break-words whitespace-nowrap">
        {start}-{end}
      </p>
    );
  };
  const handleBack = () => {
    if (location.key !== "default") {
      navigate(-1);
    } else {
      navigate(`/`);
    }
  };

  async function getCurrentTimeInSingapore() {
    try {
      const response = await getCountrytime({
        time_zone: "Asia/Singapore",
      });

      if (response.data.message.status_code !== 200) {
        throw new Error(
          `Error fetching time for Singapore: ${response.statusText}`
        );
      }

      const data = response.data.data;
      const dateString = data.current_time; // "29-07-2024 15:09:05"

      // Parse the datetime string using luxon
      const singaporeTime = DateTime.fromFormat(
        dateString,
        "dd-MM-yyyy HH:mm:ss",
        { zone: "Asia/Singapore" }
      );

      setSingaporeTime(singaporeTime);
      return singaporeTime;
    } catch (error) {
      console.error("Error fetching time:", error);
      return null;
    }
  }

  useEffect(() => {
    getCurrentTimeInSingapore();
  }, []);

  const parseTimeToSingapore = (timeStr, referenceDate) => {
    const regex = /(\d{1,2}:\d{2})([APM ]+)/i;
    const matches = timeStr.match(regex);

    const time = matches[1].trim(); // Extracts the time part
    const modifier = matches[2].trim();
    // const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return referenceDate.set({ hour: hours, minute: minutes, second: 0 });
  };

  const isSlotDisabledByTodayTime = (slotTime, pitch, isWeekView, date) => {
    if (!singaporeTime) {
      console.error("Singapore time is not set yet");
      return false;
    }

    // Use the fetched Singapore time
    let now = singaporeTime;
    let currentDate = isWeekView
      ? DateTime.fromJSDate(date).setZone("Asia/Singapore")
      : selectedDate
      ? DateTime.fromJSDate(selectedDate).setZone("Asia/Singapore")
      : now;

    // If pitch is provided, add the pitch hours to the current time
    if (pitch) {
      const pitchHours = parseInt(pitch, 10);
      now = now.plus({ hours: pitchHours });
    }

    // console.log(slotTime, "timing error");

    const [startTime, endTime] = slotTime.split("-").map((time) => time.trim());

    // console.log(startTime, "error find");

    const slotStartTime = parseTimeToSingapore(startTime, currentDate);

    // Compare slotStartTime with the adjusted now time
    // console.log(slotStartTime < now);
    return slotStartTime < now;
  };
  const renderContent = () => {
    const boxStyle = {
      width: "150px",
      height: "40px",
      // border: "1px solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "5px",
    };

    const headingBoxStyle = {
      ...boxStyle,
    };

    if (selectedTab === "day") {
      const slots = [];
      const formattedDate = selectedDate.toISOString().split("T")[0];

      // Check if the formatted date exists in the holiday list
      const isHoliday = holidayList?.some(
        (holiday) => holiday.holiday_date === formattedDate
      );
      return (
        <div className="flex mt-2 mb-12 pitches">
          <div className="mt-5 mr-5 pitch-day">
            {selectedPitch && (
              <div className="w-3/4">
                <div className="flex text-white">
                  <div className="flex-col timing mr-4">
                    <div className="flex justify-end">
                      <div
                        className={`headingBoxStyle arw-left`}
                        style={{
                          width: "30px",
                          cursor:
                            selectedDisplaypitche === "All Pitches"
                              ? "pointer"
                              : "default",
                          visibility:
                            selectedDisplaypitche === "All Pitches"
                              ? "visible"
                              : "hidden",
                        }}
                        onClick={
                          selectedDisplaypitche === "All Pitches"
                            ? handlePrevButtonClick
                            : null
                        }
                      >
                        {"<"}
                      </div>
                    </div>
                    {[...Array(newTimeWithslots?.length)].map(
                      (_, blockIndex) => (
                        <div
                          className="timings flex flex-col p-2"
                          key={blockIndex}
                        >
                          {getTimeRange(newTimeWithslots[blockIndex]?.range)}
                        </div>
                      )
                    )}
                  </div>
                  <div>
                    {selectedDisplaypitche === "All Pitches" ? (
                      <div className="flex">
                        {visiblePitches.map((pitch, pitchIndex) => (
                          <div key={pitchIndex} className="headingBoxStyle">
                            {pitch.pitch_name}
                          </div>
                        ))}
                        {/* ---------------------------------->-------------------------------------------- */}
                        {selectedDisplaypitche === "All Pitches" && (
                          <div
                            key=">"
                            className="headingBoxStyle arw-rght"
                            style={{ width: "30px", cursor: "pointer" }}
                            onClick={handleNextButtonClick}
                          >
                            {">"}
                          </div>
                        )}
                        {/* ------------------------------------------------------------------------------ */}
                      </div>
                    ) : (
                      <div className="flex">
                        <div key="1" className="headingBoxStyle">
                          Compact
                        </div>

                        {/* ---------------------------------->-------------------------------------------- */}
                        {selectedDisplaypitche === "All Pitches" && (
                          <div
                            key=">"
                            className="headingBoxStyle arw-rght"
                            style={{ width: "30px", cursor: "pointer" }}
                            onClick={handleNextButtonClick}
                          >
                            {">"}
                          </div>
                        )}
                        {/* ------------------------------------------------------------------------------ */}
                      </div>
                    )}
                    <div>
                      {selectedDisplaypitche === "All Pitches" ? (
                        <>
                          <AllPitches
                            visiblePitches={visiblePitches}
                            newTimeWithslots={newTimeWithslots}
                            selectedPitchIcon={selectedPitchIcon}
                            selectedDisplaypitche={selectedDisplaypitche}
                            isSlotSelectedInCart={isSlotSelectedInCart}
                            isSlotDeleatable={isSlotDeleatable}
                            handleBoxClick={handleBoxClick}
                            removeFromCart={removeFromCart}
                            selectedDate={selectedDate}
                            formatTimeRangespace={formatTimeRangespace}
                            pitchWeekAvailability={pitchWeekAvailability}
                            getDayOfWeek={getDayOfWeek}
                            checkIfTimeFitsInRangeoforDay={
                              checkIfTimeFitsInRangeoforDay
                            }
                            selectedPitchName={selectedPitchName}
                            formatDate={formatDate}
                            bookedPitches={bookedPitches}
                            singaporeTime={singaporeTime}
                          />
                        </>
                      ) : (
                        <>
                          <Compact
                            visiblePitches={visiblePitches}
                            newTimeWithslots={newTimeWithslots}
                            selectedPitchIcon={selectedPitchIcon}
                            handleBoxClick={handleBoxClick}
                            bookedPitches={bookedPitches}
                            formatDate={formatDate}
                            selectedDate={selectedDate}
                            pitchWeekAvailability={pitchWeekAvailability}
                            singaporeTime={singaporeTime}
                            selectedPitch={selectedPitch}
                            BookingOptions={bookingOptions}
                            SelectedPitchName={selectedPitchName}
                          ></Compact>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (selectedTab === "week") {
      return (
        <div>
          <WeekCalendar
            prevWeek={prevWeek}
            nextWeek={nextWeek}
            startDate={startDate}
            formatDateToDayMonth={formatDateToDayMonth}
            newTimeWithslotsWeek={newTimeWithslotsWeek}
            pitchWeekAvailability={pitchWeekAvailability}
            getDayOfWeek={getDayOfWeek}
            formatTimeRangespace={formatTimeRangespace}
            visiblePitches={visiblePitches}
            selectedPitchName={selectedPitchName}
            isSlotAlreadyBooked={isSlotAlreadyBooked}
            isSlotSelectedInCart={isSlotSelectedInCart}
            isSlotDeleatable={isSlotDeleatable}
            removeFromCart={removeFromCart}
            handleBoxClick={handleBoxClick}
            selectedPitchIcon={selectedPitchIcon}
            singaporeTime={singaporeTime}
          />
        </div>
      );
    }
  };

  if (!venueData) {
    return <div>Loading...</div>;
  }

  const handleBookNow = () => {
    if (venueData) {
      // Save movie data to localStorage before navigating
      console.log(venueData);
      setVenueData((prevData) => ({
        ...prevData,
        data: {
          ...prevData.data,
          venue_name: venuename.venue_name, // Add the venueName property
          address: venuename.address, // Add the venueName property
        },
      }));
      const venuedatatosave = {
        ...venueData,
        data: {
          ...venueData.data,
          venue_name: venuename.venue_name,
          address: venuename.address,
        },
      };
      localStorage.setItem("venueData", JSON.stringify(venuedatatosave));

      navigate(
        `/venue-listing-page/venue/${encodeURIComponent(
          venuedataname
        )}/booking-page/accessories`
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="book-section w-full py-4 flex justify-between items-center pl-6 py- sm:px-20 min-h-full">
        <div className="flex-grow ">
          <header>
            <div className="col-lg-8  cart-wrap ml-5">
              <button onClick={handleBack} className="backBtn ">
                <img
                  className="rounded-full nav-profile-pic"
                  src={BackButton}
                  alt="ProfileImage"
                />
                Back
              </button>
            </div>
            <div className="flex items-center mt-2">
              <div className="flex">
                <h2 className="text-3xl mt-4 font-bold text-start ml-5">
                  {venuename?.venue_name}
                  <br /> {/*venue number */}
                  {/* {venueData?.data?.address && ( */}
                  <h6 className="relative text-sm">
                    {/* {venueData.data.address} */}
                    {venuename?.address}
                  </h6>
                  {/* )} */}
                </h2>
              </div>
              <div className="mt-4 px-6">
                {!isMobileView && uniqueItems.length > 0 && (
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-3"
                    onClick={() => {
                      if (uniqueItems.length > 0) {
                        handleBookNow();
                      } else {
                        toast.warn("No  items in the cart");
                      }
                    }}
                  >
                    Next ({uniqueItems.length})
                  </button>
                )}
              </div>
            </div>
          </header>
          <div className="flex flex-wrap lg:space-x-10  ">
            <div className="text-4xl font-bold text-start booking-for">
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-select-small-label" className="">
                  Booking for
                </InputLabel>
                <Select
                  key={selectedPitch}
                  labelId="demo-select-small-label"
                  id="demo-select-small btn-one"
                  label="Booking to"
                  placeholder="Booking for"
                  open={selectOpen}
                  onClose={handleSelectClose}
                  onOpen={handleSelectOpen}
                  onChange={handleSelectChange}
                  className="select-box"
                  value={selectedPitch} // Use value prop for default value
                >
                  {bookingOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.booking_for_name}
                    </MenuItem>
                  ))}
                </Select>

                {selectedBookingOption === "" && (
                  <span className="text-lg">Please select an option</span>
                )}
              </FormControl>
            </div>
            <div className="text-4xl font-bold text-start relative pitches-shown">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-input-small-label">Pitches</InputLabel>
                <Select
                  labelId="demo-select-small-label-pitches"
                  id="demo-select-small-pitches"
                  label="Pitches"
                  placeholder="Select"
                  defaultValue={"All Pitches"}
                  open={selectOpenPitch}
                  onClose={handleSelectOpenPitch}
                  onOpen={handleSelectOpenPitch}
                  onChange={handlePitchSelectChange}
                  className="select-box"
                  value={selectedDisplaypitche}
                >
                  <MenuItem key="Compact" value="Compact">
                    Compact
                  </MenuItem>

                  <MenuItem key="All Pitches" value="All Pitches">
                    All Pitches
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {selectedPitch && (
            <div className="font-bold text-start relative flex flex-col justify-start">
              {selectedDate && <button onClick={clearDate}></button>}
              <div className="flex flex-wrap items-center mt-10 ml-1">
                <Tabs
                  value={selectedTab}
                  onChange={(event, newValue) => setSelectedTab(newValue)}
                  className=" h-8 w-max flex-shrink-0 mr-1"
                >
                  <Tab
                    label="Day"
                    value="day"
                    className="rounded-lg  border border-gray-300"
                    style={{
                      // paddingRight: "1rem",
                      borderRight: "1px solid #d6d5d5",
                      marginRight: "5px",
                      borderRadius: "0.5rem 0.5rem 0.5rem 0.5rem",
                    }}
                  >
                    <div className="absolute right-0 top-1/2 ml-1 transform -translate-y-1/2 w-px h-3/5 bg-gray-300"></div>
                  </Tab>
                  <Tab
                    label="Week"
                    value="week"
                    style={{
                      borderRadius: "0.5rem 0.5rem 0.5rem 0.5rem",
                    }}
                    className="rounded-lg border border-gray-300"
                  />
                </Tabs>
                {selectedTab === "day" && (
                  <div className="">
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      placeholderText="Select a date"
                      dateFormat="yyyy/MM/dd"
                      minDate={new Date()}
                      maxDate={futuredays}
                      customInput={<CustomInput />}
                      className="border text-center border-gray-300 rounded-lg"
                      popperClassName="mr-4"
                    />
                  </div>
                )}
                {selectedTab === "week" && (
                  <div className="week-selector flex items-center  rounded-lg px-2 ">
                    <button
                      disabled={loading || clickCount <= 0}
                      ref={buttonPrevClick}
                      onClick={prevWeek}
                      className="mr-2"
                    >
                      <img src={LeftButton} alt="Left Navigation button" />
                    </button>

                    <h4 className="mx-2 text-sm mt-2">
                      {formatDateToDayMonth(startDate)} -{" "}
                      {formatDateToDayMonth(endDate)}
                    </h4>

                    <button
                      ref={buttonNextDisable}
                      onClick={nextWeek}
                      className="ml-2"
                    >
                      <img src={RightButton} alt="Right Navigation button" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="relative overflow-hidden nowrap mt-2 marquee-container">
            <span className="inline-block animate-marquee">
              The calendar is restricted to Singapore time zone. UTC +8
            </span>
          </div>

          {/* <div className="relative overflow-hidden whitespace-nowrap mt-2">
            <span className="inline-block animate-marquee">
              The calendar is restricted to Singapore time zone. UTC +8
            </span>
          </div> */}
          {loading ? <LoadingBackdrop open={loading} /> : renderContent()}
          {selectedPitch && (
            <div className="flex justify-start mb-12 day-week-tab">
              {!isMobileView && (
                <button
                  className="bg-red-500 hover:bg-red-700 text-white  font-bold py-2 px-4 sm:px-2 rounded  view-cart-button ml-14"
                  onClick={() => {
                    if (cartItems.length > 0) {
                      handleBookNow();
                    } else {
                      toast.warn("No items in the cart");
                    }
                  }}
                >
                  Next ({cartItems.length})
                </button>
              )}
              <div className=" justify-end">
                {isMobileView && (
                  <Button
                    onClick={() => {
                      if (cartItems.length > 0) {
                        handleBookNow();
                      } else {
                        toast.warn("No items in the cart");
                      }
                    }}
                    className="cartBtn"
                  >
                    <span>Next ({cartItems.length})</span>
                  </Button>
                )}
              </div>
            </div>
          )}

          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
              <div className="bg-white p-8 rounded-lg z-10 fly-cart">
                <h2 className="text-2xl font-bold mb-4">
                  Pitch you Selected:
                  {popupInfo.pitch?.pitch_name ||
                    (sortedpitche.length > 0 ? sortedpitche[0].name : "")}
                </h2>
                <p className="mb-2">
                  Time: {convertTo24HourFormattest(popupInfo.time)}
                </p>
                {/* <p className="mb-4">Date: {formatDate(popupInfo.date)}</p> */}
                <p className="mb-4">
                  Date: {convertDateFormat(popupInfo.date)}
                </p>
                {true && (
                  <div className="mt-8">
                    <Select
                      key={selectedPitch}
                      id="demo-select-small btn-one"
                      placeholder="Booking for"
                      open={selectOpenpitches}
                      onClose={handleSelectOpenPitches}
                      onOpen={handleSelectOpenPitches}
                      onChange={(event) => {
                        const selectedPitch = event.target.value;
                        const selectedPitchItem = sortedpitche.find((item) => {
                          return selectedPitch === item.name;
                        });

                        setPopupInfo({
                          pitch: selectedPitchItem,
                          time: formateTime(popupInfo.time),
                          date: formatDate(popupInfo.date),
                        });
                      }}
                      className="select-box mb-3"
                      value={
                        popupInfo.pitch?.name ||
                        (sortedpitche.length > 0
                          ? sortedpitche[0].name
                          : defaultPiche[0])
                      }
                      style={{ minWidth: "100px" }}
                      defaultValue={defaultPiche[0]}
                    >
                      {sortedpitche.map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                          {option.pitch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}

                {true && (
                  <div className="mt-8">
                    <Select
                      key={selectedPitch}
                      id="demo-select-small btn-one"
                      placeholder="Booking for"
                      open={selectOpenProduct}
                      onClose={handleSelectOpenProduct}
                      onOpen={handleSelectOpenProduct}
                      onChange={(value) => {
                        setSelectedServiceOptions(value.target.value);

                        let selecteditem = serviceItems.find(
                          (item) => item?.label === value.target.value
                        );
                        let payload = {
                          item_code: value.target.value,
                          rate: selecteditem.price,
                        };
                      }}
                      className="select-box mb-3 "
                      value={
                        selectedServiceOptions?.label
                          ? selectedServiceOptions?.label
                          : selectedServiceOptions
                      }
                      style={{ minWidth: "100px" }}
                      defaultValue={serviceItems[0]}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 160,
                          },
                        },
                      }}
                    >
                      {serviceItems.map((option) => (
                        <MenuItem key={option?.label} value={option?.label}>
                          {option?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
                <div className="flex justify-between">
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleAdd}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal
          title="Confirm Remove Item"
          visible={isModalVisible}
          onOk={handleselectmodel}
          onCancel={() => setIsModalVisible(false)}
          className="removeCartPopup"
        >
          <p>Are you sure you want to remove All item from the cart?</p>
        </Modal>
      </div>
    </>
  );
};

export default PitchSelectionTable;
