import React from "react";
import { FaFacebookSquare, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import Logo from "../../assets/images/logoknox.png";
import MailIcon from "../../assets/images/mail-icon.svg";

const Footer = () => {
  return (
    <footer className="w-full">
      
      {/* <div className="  bg-[#F6E0E1] max-w-full px-6 lg:px-20 py-4 flex flex-col lg:flex-row items-center newsLetter">
        <div className="flex flex-col mb-4 lg:mb-0 lg:mr-auto">
          <span className="text-lg font-bold newsletter-head">Newsletter</span>
          <span className="newsfoot">
            Be the first one to know about discounts, offers and events
          </span>
        </div>

        <div className="flex ml-auto newsletter">
          <form
            action=""
            className="flex rounded-26 items-center w-full lg:w-96"
          >
            <div className="flex items-center justify-between newsletterSubmit rounded-full w-full lg:w-96 ">
              <div className="flex gap-2 items-center icon-input">
                <img src={MailIcon} alt="Email" className="h-4" />
                <input
                  className="Footer-news bg-transparent w-full text-sm placeholder:text-black"
                  type="email"
                  placeholder="Enter your Email" 
                />
              </div>
              <button
                type="button"
                className="  w-20 h-8 rounded-full border-none bg-white text-[#ED1C25] text-xs font-semibold cursor-pointer"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div> */}

      
      <div className="mx-auto max-w-full px-6 lg:px-20 flex flex-col lg:flex-row items-start space-x-6 md:space-x-14 footer-bottom">
        
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-0 mt-10 lg:mt-0">
          <div className="lg:pr-4 footer-logo mb-2">
            <img src={Logo} alt="Logo" className="h-16" />
            <p className="mt-4 text-sm">
            WELCOME TO KNOX, WHERE WE REDEFINE TRAINING AS A HOLISTIC AND COMMUNITY-DRIVEN EXPERIENCE. OUR STATE-OF-THE-ART STUDIO BRIDGES THE GAP BETWEEN WELLNES
S AND FITNESS.WE ARE DEDICATED TO YOUR OVERALL WELL-BEING, OFFERING PERSONALISED, RESULTS-ORIENTED TRAINING PROGRAMS THAT MAKE A TANGIBLE DIFFERENCE I
N YOUR LIFE. BUT KNOX IS MORE THAN JUST A FITNESS STUDIO.
            </p>
          </div>
          <div className="mb-0 lg:mb-0">
            <h4 className="mb-2">Company</h4>
            <ul>
              <li>
                
                  About us
                
              </li>
              <li>
               
                  Legal Information
                
              </li>
              <li>
                
                  Contact Us
                
              </li>
              <li>
               
                  Blogs
                
              </li>
            </ul>
          </div>
          <div className="mb-0 lg:mb-0">
            <h4 className="mb-2">HELP CENTER</h4>
            <ul>
              <li>
               
                  Find a Venue
                
              </li>
              <li>
                
                  Why Us?
               
              </li>
              <li>
                
                  FAQs
                
              </li>
              <li>
                
                  Booking Guides
               
              </li>
            </ul>
          </div>

          <div className="mb-2 lg:mb-0">
            <h4 className="mb-2">Contact Info</h4>
            <ul className="text-gray-600">
              <li>Phone: 65634<span>&#8203;</span>49345</li>
              
              <li>Email: <a href="mailto:knox@gmail.com">knox@gmail.com<span>&#8203;</span></a></li>
              <li>
                Location: Opposite Flyover, Plot No. 34, Lajpat Nagar 4, New Delhi, South Delhi, Delhi 110024
              </li>
            </ul>
            <div className="pt-3 flex social-media">
              <a href="https://twitter.com/"   className="mx-2">
                <FaTwitter className="w-6 h-6 " />
              </a>
              <a href="https://www.facebook.com/"   className="mx-2">
                <FaFacebookSquare className="w-6 h-6 " />
              </a>
              <a href="https://www.instagram.com/"   className="mx-2">
                <FaInstagram className="w-6 h-6 " />
              </a>
              <a href="https://www.linkedin.com/"   className="mx-2">
                <FaLinkedin className="w-6 h-6 " />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex max-w-full flex-col lg:flex-row justify-center items-center px-6 lg:px-20 py-6 footer-copyright">
        <div className="copyright">
          © Copyright 2024 Mind Media | All Rights Reserved
        </div>
        <div className="poweredBy">Powered by Mind Media</div>
      </div>
    </footer>
  );
};

export default Footer;
