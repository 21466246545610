import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../component/common/NavbarProfile";
import "./index.css";
import "../index.css";
import { isNumber } from "util";
import {
  handleCreateOrder,
  validateCouponCode,
} from "../../handlers/product/api";
import { toast } from "react-toastify";
import image from "../../assets/images/cardimage.png";
import { Card, Input, Modal } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "antd";
import {
  fetchTaxApi,
  requestPayment,
  setPaymentUrlOnSo,
} from "../../handlers/Payment/requestpayment";
import Defaultimage from "../../assets/images/CricketKit.jpg";
import LogInPopup from "../AuthScreen/LogInPopup";
import BackButton from "../../assets/images/backBtn.svg";
import ReddotPyament from "../PaymentScreen/RequestUrlPayment";
import { CrossIcon, Notebook, ShoppingBagIcon } from "lucide-react";
import { Close, ShoppingBasket, ShoppingCart } from "@mui/icons-material";

import { getAlreaduBookedPitches } from "../../handlers/product/api";
import {
  directpaybypayerid,
  fetchUserDataApi,
  fetchUserDatasApi,
} from "../../handlers/myaccount/api";
import { prettyDOM } from "@testing-library/react";
import CardSelectionPopup from "./CardSelectionPopup";
import PhoneNumberModal from "./Phonenumbermodel";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [product_Service, setProduct_Service] = useState();
  const [venueData, setVenueData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const { Meta } = Card;
  const [orderDetails, setOrderDetails] = useState([]);
  const [paymentURL, setPaymentURL] = useState([]);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountdisplay, setdiscountdisplay] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [booked, setbooked] = useState([]);
  const [appliedCouponCode, setAppliedCouponCode] = useState("");
  const [userData, setUserData] = useState("");
  const [totalHr, setTotalHr] = useState(0);
  const [showCardSelectionPopup, setShowCardSelectionPopup] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [couponApplied, setCouponApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isphoneModalVisible, setIsphoneModalVisible] = useState(false);

  let flag = 0; // dont convert to usestate
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cartItems")) || [];
    console.log("items", items);
    const uniqueItems = items.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.name === item.name && t.date === item.date && t.time === item.time
        )
    );

    console.log("Unique Items:", uniqueItems);

    setCartItems(uniqueItems);
    const product_Service =
      JSON.parse(localStorage.getItem("Product_Service")) || {};
    setProduct_Service(product_Service);
    const venue_data = JSON.parse(localStorage.getItem("venueData")) || {};
    setVenueData(venue_data);

    checkIfMobile(); // check on initial load
    window.addEventListener("resize", checkIfMobile); // add event listener for window resize
    return () => {
      window.removeEventListener("resize", checkIfMobile); // clean up on component unmount
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
    }, 0);
  
    return () => clearTimeout(timeoutId);
  }, [location]);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
  }, [location]);

  const handleBack = () => {
    if (location.key !== "default") {
      navigate(-1);
      // window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
    } else {
      navigate(`/`);
      // window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
    }
  };

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      const userData = await fetchUserDatasApi(token);
      return userData;
    } catch (error) {
      console.error(error.message);
      return {
        email: "",
        phone: "",
        // image: profileIcon,
      };
    }
  };

  const toggleCardPopup = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setShowLoginPopup(true);
    } else {
      const data = await fetchUserData();
      console.log(data, "data");

      if (data.phone == "") {
        console.log("error");
        setIsphoneModalVisible(true);
        // setShowCardSelectionPopup((prevState) => !prevState);
      } else {
        setShowCardSelectionPopup((prevState) => !prevState);
      }
    }
  };
  const formatTimeRangeView = (timeRange) => {
    if (timeRange) {
      const formatTime = (time) => {
        if (time.split(" ")[1] == "PM") {
          if (parseInt(time.split(" ")[0].split(":")[0]) == 12) {
            return `${parseInt(time.split(" ")[0].split(":")[0])}:${
              time.split(" ")[0].split(":")[1]
            }`;
          }
          return `${
            parseInt(time.split(" ")[0].split(":")[0]) + parseInt(12)
          }:${time.split(" ")[0].split(":")[1]}`;
        } else {
          if (parseInt(time.split(" ")[0].split(":")[0]) == 12) {
            return `${
              parseInt(time.split(" ")[0].split(":")[0]) + parseInt(12)
            }:${time.split(" ")[0].split(":")[1]}`;
          }
          return time.split(" ")[0];
        }
      };
      const [start, end] = timeRange.split("-");

      const formattedStart = formatTime(start);

      const formattedEnd = formatTime(end);

      return `${formattedStart}-${formattedEnd}`;
    } else {
      return;
    }
  };

  const processData = (data) => {
    // Filter items with item_category "Services"
    const servicesData = data.filter(
      (item) => item.item_category === "Services"
    );

    // Group items by time and date
    const groupedData = servicesData.reduce((acc, item) => {
      const { time, date, rate, minutes } = item;
      const key = `${date}_${time}_${rate}_${minutes}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    // Combine pitches with the same time and date
    const combinedData = Object.entries(groupedData).map(([key, items]) => {
      const [date, time, rate, minutes] = key.split("_");
      const pitches = items.map((item) => item.pitch_name);
      const sortedPitches = Array.from(new Set(pitches)).sort();
      const combinedPitches = sortedPitches.join(", ");
      return {
        time,
        name: `${items[0].name.replace(
          /\|([^|]+)\|([^|]+)$/,
          `| ${combinedPitches} |$2`
        )}`,
        item_category: "Services",
        pitch: combinedPitches,
        date,
        rate,
        minutes,
      };
    });

    return combinedData;
  };

  const ungroupNames = (name) => {
    const regex = /\| ([^|]+) \|/;
    const matches = name.match(regex);
    if (matches && matches.length > 1) {
      const pitches = matches[1].split(", ");
      return pitches.map(
        (pitch) => `${name.replace(matches[0], `| ${pitch} |`)}`
      );
    }
    return [name];
  };

  const confirmRemove = (item) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };

  const removeFromCart = () => {
    const itemToRemove = selectedItem;
    console.log(itemToRemove);
    const updatedCartItems = cartItems.filter((item) => {
      console.log(ungroupNames(itemToRemove.name), " ____ ", item);
      return (
        !ungroupNames(itemToRemove.name).includes(item.name) ||
        item.time !== itemToRemove.time ||
        item.date !== itemToRemove.date
      );
    });

    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    setIsModalVisible(false); // Close the modal
    setSelectedItem(null); // Reset the selected item
  };

  const numberofPitchesInCart = () => {
    const updatedCartItems = cartItems.filter(
      (item) => item.item_category === "Services"
    );
    return updatedCartItems.length;
  };

  function convertTo24hrDateTime(dateString, timeRangeString) {
    // Parse the date string
    const dateParts = dateString.split(" ");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames.indexOf(dateParts[1]) + 1;
    const day = parseInt(dateParts[0], 10); // Parse the day as an integer
    const year = parseInt(dateParts[2], 10); // Parse the year as an integer

    // Parse the time range string
    const [startTime, endTime] = timeRangeString.split("-");

    // Convert start time to 24-hour format
    const [startHour, startMinute] = startTime
      .split(":")
      .map((part) => parseInt(part, 10));
    const startDate = new Date(
      year,
      month - 1,
      day,
      (startHour % 12) + (startTime.includes("PM") ? 12 : 0),
      startMinute
    );
    const startDay = startDate.getDate().toString().padStart(2, "0"); // Ensure day has leading zero if necessary
    const startMonth = (startDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has leading zero if necessary
    const startYear = startDate.getFullYear();
    const startDateTimeString = `${startYear}-${startMonth}-${startDay} ${
      startDate.toTimeString().split(" ")[0]
    }`;

    // Convert end time to 24-hour format
    const [endHour, endMinute] = endTime
      .split(":")
      .map((part) => parseInt(part, 10));
    let endDate = new Date(
      year,
      month - 1,
      day,
      (endHour % 12) + (endTime.includes("PM") ? 12 : 0),
      endMinute
    );
    if (endHour == 12 && endTime.includes("AM")) {
      endDate = new Date(year, month - 1, day, 23, 59, 59);
    }
    const endDay = endDate.getDate().toString().padStart(2, "0"); // Ensure day has leading zero if necessary
    const endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has leading zero if necessary
    const endYear = endDate.getFullYear();
    const endDateTimeString = `${endYear}-${endMonth}-${endDay} ${
      endDate.toTimeString().split(" ")[0]
    }`;

    return [startDateTimeString, endDateTimeString];
  }
  const toggleLoginPopup = () => {
    setShowLoginPopup(!showLoginPopup);
  };

  useEffect(() => {
    // console.log(showLoginPopup);
  }, [showLoginPopup]);

  function formatDateforGetAlreadyBookedPitches(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  function getNextDate(dateString) {
    const currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() + 1); // Adding one day
    return currentDate.toISOString().split("T")[0]; // Convert to ISO date string (YYYY-MM-DD)
  }

  function parseTimeRange(timeRange) {
    const [startTime, endTime] = timeRange.split("-").map((time) => {
      const [timeString, modifier] = time.trim().split(" ");
      let [hours, minutes] = timeString.split(":").map(Number);
      if (modifier === "PM" && hours !== 12) hours += 12;
      if (modifier === "AM" && hours === 12) hours = 0;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:00`;
    });
    return { startTime, endTime };
  }

  async function checkAlreadyBookedPitchesForItem(item) {
    let payload = {
      venue: venueData.data.name,
      booking_for: venueData.booking_for,
      start_date: formatDateforGetAlreadyBookedPitches(item.date),
      end_date: getNextDate(formatDateforGetAlreadyBookedPitches(item.date)),
    };

    console.log("Payload:", payload);

    try {
      const res = await getAlreaduBookedPitches(payload);
      console.log("Booked data for item:", res.data.message);

      let bookedData = res.data.message;
      console.log("Item to compare:", item);

      let isAlreadyBooked = bookedData.some((bookedItem) => {
        console.log("Comparing pitches:", bookedItem.custom_pitch, item.pitch);

        if (bookedItem.custom_pitch !== item.pitch) {
          return false;
        }

        console.log("Pitch match");
        console.log("Booked Item:", bookedItem, "Cart Item:", item);

        const { startTime, endTime } = parseTimeRange(item.time);
        let date = formatDateforGetAlreadyBookedPitches(item.date);
        console.log("Formatted Date:", date);

        let startDateTime = `${date} ${startTime}`;
        let endDateTime = `${date} ${endTime}`;
        return (
          bookedItem.starts_on === startDateTime &&
          bookedItem.ends_on === endDateTime
        );
      });

      if (isAlreadyBooked) {
        console.log(item);
        toast.error(
          `Item already booked: ${item.name} with ${item.date} and ${item.time}`
        );
        return false;
      } else {
        console.log(`Item not booked, proceeding:`, item);
        return true;
      }
    } catch (error) {
      console.error("Error fetching booked pitches for item:", error);
      return false;
    }
  }

  const createOrder = async (card) => {
    setLoading(true);
    for (let item of cartItems) {
      console.log(item, "hhhh");
      if (item.item_category == "Services") {
        const isAvailable = await checkAlreadyBookedPitchesForItem(item);

        console.log("is available", isAvailable);
        if (!isAvailable) {
          console.log("Order creation stopped due to a booked item:", item);
          setLoading(false);

          return;
        }
      }
    }

    const token = localStorage.getItem("token");
    if (!token) {
      // console.log("here");
      setShowLoginPopup(true);
    } else {
      const totalmins = cartItems
        .filter((item) => item.item_category === "Services")
        .reduce((totalMinutes, item) => {
          return totalMinutes + item.minutes;
        }, 0);
      let payload = {
        booking_for: venueData.booking_for,
        venue: venueData.data.name,
        pitches_slots: cartItems
          .filter((item) => item.item_category === "Services")
          .map((pitch) => ({
            pitch_name: pitch.pitch,
            item_code: pitch.item_code,
            item_category: "Services",
            rate: pitch.rate,
            booking_state: "In Progress",
            slot_start_date_time: convertTo24hrDateTime(
              pitch.date,
              pitch.time
            )[0],
            slot_end_date_time:
              convertTo24hrDateTime(pitch.date, pitch.time)[1] === "00:00:00"
                ? "23:59:59"
                : convertTo24hrDateTime(pitch.date, pitch.time)[1],
          })),
        consumable_products: cartItems
          .filter((item) => item.item_category === "Products")
          .map((item) => ({
            item_code: item.name,
            qty: item.quantity,
            rate: item.price,
            item_category: "Products",
          })),
        rental_products: cartItems
          .filter((item) => item.item_category === "Rental")
          .map((item) => ({
            item_code: item.name,
            qty: item.quantity,
            //qty: item.quantity * processData(cartItems).length,
            rate: item.per_minuts_price,
            minutes: totalmins,
            item_category: "Rental",
          })),
        services_products_002: cartItems
          .filter((item) => item.item_category === "services_products_002")
          .map((item) => ({
            item_code: item.name,
            qty: item.quantity,
            rate: item.price,
            minutes: totalmins,
            item_category: "Rental",
          })),

        coupon_code: couponCode,
      };
      console.log(payload, "payload");

      try {
        const response = await handleCreateOrder(payload, token);

        if (response) {
          toast.success("Order has been Created!");
          const orderDetails = {
            order: {
              booking_for: venueData.booking_for,
              venue: venueData.data.name,
              order_id: response.data.data.ref_number.so_no,
              amount: response.data.data.ref_number.amount,
              // card_no: "4111111111111111",
              // cvv2: "111",
              // exp_date: "102025",
              booking_code: 0,
            },
          };
          //

          const token = localStorage.getItem("token");
          if (token && card !== "New") {
            try {
              const res = await fetchUserDataApi(token);
              console.log(res);
              setUserData(res.data.saved_cards);
              console.log(res.data.saved_cards);
              const cards = res.data.saved_cards;
              const userd = res.data;
              if (cards && cards.length > 0) {
                // Filter for the preferred payment method card
                let prefcard = cards.filter(
                  (card) => card.preferred_payment_method === 1
                );

                console.log(prefcard);

                // If no preferred card is found, select the first card with a payer_id
                if (prefcard.length === 0) {
                  prefcard = cards.find((card) => card.payer_id);
                }
                console.log(prefcard);
                if (Array.isArray(prefcard)) {
                  prefcard = prefcard[0];
                }
                console.log(prefcard);
                const payload = {
                  order: {
                    amount: orderDetails.order.amount,
                    order_id: orderDetails.order.order_id,
                    payer_id: card?.payer_id,
                    payer_email: userd.email,
                    payer_name: userd.full_name,
                    venue: venueData.data.name,
                    booking_for: venueData.booking_for,
                    booking_code: 0,
                  },
                };
                console.log(payload);
                if (prefcard && card?.payer_id) {
                  flag = 1;
                  const res = await directpaybypayerid(payload);
                  console.log(res);
                  if (res.message.response_msg === "successful") {
                    localStorage.setItem("last_4", res.message.last_4);
                    localStorage.setItem("first_6", res.message.first_6);
                    localStorage.setItem("payer_id", res.message.payer_id);
                    localStorage.setItem(
                      "request_mid",
                      res.message.request_mid
                    );
                    localStorage.setItem(
                      "transaction_id",
                      res.message.transaction_id
                    );
                    navigate(
                      `/payment-successfull/3/0?transaction_id=${res.message.transaction_id}`
                    );
                  } else {
                    toast.error("Payment Failed");
                  }
                } else {
                  flag = 1;
                  const res = await requestPayment(orderDetails);
                  // comment the bellow code to check payment url

                  if (res.status === 200) {
                    setPaymentURL(res.data.message);
                    const payloadForSettingPaymentUrlOnSo = {
                      so_no: orderDetails.order.order_id,
                      payment_url: res.data.message.payment_url,
                    };
                    const res2 = await setPaymentUrlOnSo(
                      payloadForSettingPaymentUrlOnSo,
                      token
                    );

                    window.location.href = res.data.message.payment_url;
                  }
                  setOrderDetails(response.data.data.ref_number);
                  console.log(response.data.data.ref_number, "data");
                }
              }
            } catch (e) {
              console.log(e);
            }
            if (flag == 0 && (card === "New" || !card?.payer_id)) {
              const res = await requestPayment(orderDetails);
              // comment the bellow code to check payment url

              if (res.status === 200) {
                setPaymentURL(res.data.message);

                const payloadForSettingPaymentUrlOnSo = {
                  so_no: orderDetails.order.order_id,
                  payment_url: res.data.message.payment_url,
                };
                const res2 = await setPaymentUrlOnSo(
                  payloadForSettingPaymentUrlOnSo,
                  token
                );

                window.location.href = res.data.message.payment_url;
              }
              setOrderDetails(response.data.data.ref_number);
              console.log(response.data.data.ref_number, "data");
            }
          }
          if (flag == 0 && (card === "New" || !card?.payer_id)) {
            // comment the bellow code to check payment url
            const res = await requestPayment(orderDetails);

            if (res.status === 200) {
              setPaymentURL(res.data.message);

              console.log(orderDetails);
              const payloadForSettingPaymentUrlOnSo = {
                so_no: orderDetails.order.order_id,
                payment_url: res.data.message.payment_url,
              };
              console.log(payloadForSettingPaymentUrlOnSo, token);
              const res2 = await setPaymentUrlOnSo(
                payloadForSettingPaymentUrlOnSo,
                token
              );

              window.location.href = res.data.message.payment_url;
            }
            setOrderDetails(response.data.data.ref_number);
            console.log(response.data.data.ref_number, "data");
          }

          // localStorage.removeItem("cartItems");
        } else {
          toast.warning("Failed to create order");
          setLoading(false);
        }
      } catch (error) {
        toast.error("Failed to create order");
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleCardSelect = (card) => {
    console.log(card);
    setSelectedCard(card);

    if (card === "Add New Card") {
      createOrder("New");
    } else {
      createOrder(card);
    }
  };

  function calculateHourDifference(timerange) {
    // Split the timerange into start and end times
    const [startTime, endTime] = timerange
      .split("-")
      .map((time) => time.trim());

    // Function to convert time to minutes
    const timeToMinutes = (time) => {
      const [hourStr, minuteStr, meridiem] = time.split(/:| /);
      let hour = parseInt(hourStr, 10);
      const minute = parseInt(minuteStr, 10);

      // Special case for 12 AM (midnight)
      if (meridiem === "AM" && hour === 12) {
        hour = 0;
      }

      // Adjust hour for PM time
      if (meridiem === "PM" && hour !== 12) {
        hour += 12;
      }

      return hour * 60 + minute;
    };

    // Convert start time to minutes
    const startMinutes = timeToMinutes(startTime);

    // Convert end time to minutes
    const endMinutes = timeToMinutes(endTime || startTime); // Use start time if end time is undefined

    // Calculate the difference in minutes
    let hourDifference = (endMinutes - startMinutes) / 60;

    // If the difference is negative, it means the time range crosses midnight
    if (hourDifference < 0) {
      hourDifference += 24; // Add 24 hours to adjust
    }

    return hourDifference;
  }
  useEffect(() => {
    caltothr();
  }, [cartItems]);

  const caltothr = () => {
    let total_hours = cartItems
      .filter((item) => item.item_category === "Services")
      .reduce((acc, item) => {
        // console.log(item);
        // Check if item price is a valid number
        const price =
          typeof item.price === "number" && !isNaN(item.price) ? item.price : 0;
        // Add the valid price to the accumulator
        const quantity =
          typeof item.quantity === "number" && !isNaN(item.quantity)
            ? item.quantity
            : 1;
        const hours = calculateHourDifference(item.time);
        console.log(hours);
        // Add the valid price to the accumulator
        return acc + hours;
      }, 0);

    console.log(total_hours);
    setTotalHr(total_hours);
  };

  const calculateTotal = () => {
    let total_hours = cartItems
      .filter((item) => item.item_category === "Services")
      .reduce((acc, item) => {
        // console.log(item);
        // Check if item price is a valid number
        const price =
          typeof item.price === "number" && !isNaN(item.price) ? item.price : 0;
        // Add the valid price to the accumulator
        const quantity =
          typeof item.quantity === "number" && !isNaN(item.quantity)
            ? item.quantity
            : 1;
        const hours = calculateHourDifference(item.time);
        // console.log(hours);
        // Add the valid price to the accumulator
        return acc + hours;
      }, 0);

    // console.log("hours",total_hours,acc);
    let partA = cartItems
      .filter((item) => item.item_category !== "Services")
      .reduce((acc, item) => {
        // Check if item price is a valid number
        const price =
          typeof item.price === "number" && !isNaN(item.price) ? item.price : 0;
        // Add the valid price to the accumulator
        const quantity =
          typeof item.quantity === "number" && !isNaN(item.quantity)
            ? item.quantity
            : 1;
        // Add the valid price to the accumulator
        console.log("11111 sample", price, quantity, total_hours);
        let tot =
          item.item_category === "Rental"
            ? acc + price * quantity * total_hours
            :  acc + price * quantity;
        return tot;
      }, 0);

    // console.log(partA);
    console.log(partA);
    let partB = cartItems
      .filter((item) => item.item_category === "Services")
      .reduce((acc, item) => {
        // Check if item price is a valid number
        const price = item.rate;
        // Add the valid price to the accumulator
        const quantity =
          typeof item.quantity === "number" && !isNaN(item.quantity)
            ? item.quantity
            : 1;
        // Add the valid price to the accumulator
        console.log(acc + price * quantity, price, quantity, total_hours);
        return acc + price * quantity;
      }, 0);

    let n = partA + partB;
    // console.log(n,partA,partB,"money fetched")
    return n.toFixed(2);
  };

  const calculateTotalWithGST = (rate) => {
    const subtotal = calculateTotal() - discount;
    const gstRate = rate;
    const gstAmount = (subtotal * gstRate) / 100;
    // Use toFixed to round and format to two decimal places
    const roundedGstAmount = parseFloat(gstAmount.toFixed(2));
    return roundedGstAmount;
  };

  const FinalTotalAmt = () => {
    let subtotal = calculateTotal() - discount;

    let totalTax = taxData?.reduce((acc, item) => {
      const gstAmount = calculateTotalWithGST(item.rate);
      return acc + gstAmount;
    }, 0);
    if (!totalTax) {
      totalTax = 0;
    }

    return (Math.round((subtotal + totalTax) * 100) / 100).toFixed(2);
  };

  // Function to check if current view is mobile
  const checkIfMobile = () => {
    setIsMobileView(window.innerWidth <= 768); // set to true if screen width is less than or equal to 768px
  };

  // Function to toggle cart popup
  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const calculateTimeLeft = useCallback(() => {
    const endTime = localStorage.getItem("countdownEndTime");
    if (!endTime) {
      return "not there";
    }
    const now = new Date().getTime();
    const timeRemaining = Math.max((endTime - now) / 1000, 0);
    return timeRemaining;
  }, []);

  // Set up the initial end time or retrieve from local storage
  useEffect(() => {
    const savedEndTime = localStorage.getItem("countdownEndTime");
    if (!savedEndTime) {
      const endTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes from now
      localStorage.setItem("countdownEndTime", endTime);
    }
    setTimeLeft(calculateTimeLeft());
  }, [calculateTimeLeft]);

  const fetchTaxData = async () => {
    try {
      const res = await fetchTaxApi(venueData?.booking_for);
      // console.log(res);
      if (res.status === 200) {
        setTaxData(res.data.data.taxes);
        // console.log("tax", res.data.data.taxes);
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  };

  useEffect(() => {
    if (venueData?.booking_for) {
      fetchTaxData();
    }
  }, [venueData]);

  // Update the countdown every second
  useEffect(() => {
    if (isRunning) {
      const intervalId = setInterval(() => {
        const timeRemaining = calculateTimeLeft();
        setTimeLeft(timeRemaining);
        if (timeRemaining === "not there") {
          return;
        }
        if (timeRemaining <= 0) {
          clearInterval(intervalId);
          localStorage.removeItem("countdownEndTime");
          // localStorage.removeItem("cartItems");
          setCartItems([]);

          setIsRunning(false);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isRunning, calculateTimeLeft]);

  const startTimer = (restart = true) => {
    if (restart) {
      const endTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes from now
      localStorage.setItem("countdownEndTime", endTime);
    }
    setTimeLeft(calculateTimeLeft());
    setIsRunning(true);
  };

  useEffect(() => {
    const savedEndTime = localStorage.getItem("countdownEndTime");

    if (savedEndTime) {
      setTimeLeft(calculateTimeLeft());
      setIsRunning(true);
    }
  }, [calculateTimeLeft]);
  // Format the time left as MM:SS
  const formatTimeForCountDown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (
      storedCartItems?.length === 0 ||
      storedCartItems === undefined ||
      storedCartItems === null
    ) {
      localStorage.removeItem("countdownEndTime");
    } else {
      calculateTimeLeft();
    }
    if (cartItems.length > 0) {
      startTimer(false);
    }
  }, [cartItems]);

  const handleCouponCodeChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleApplyCoupon = async () => {
    if (!cartItems.length > 0) {
      toast.error("Please add first item");
      return;
    }

    if (couponCode.trim() === "") {
      toast.error("Please enter a coupon code");
      return;
    }

    const totalAmount = FinalTotalAmt();

    try {
      const response = await validateCouponCode(
        couponCode,
        Math.floor(totalAmount)
      );
      if (response.data.message.status_code === 200) {
        setdiscountdisplay(true);
        setCouponApplied(true);
        setDiscount(response.data.message.discount_amount);
        if (appliedCouponCode === couponCode) {
          toast.warning("Coupon already applied!");
        } else {
          toast.success("Coupon applied successfully!");
          setAppliedCouponCode(couponCode);
        }
      } else {
        setDiscount(0);
        toast.error("Invalid coupon code");
      }
    } catch (error) {
      // setDiscount(0);
      toast.error("Error applying coupon code");
    }
  };

  const handleRemoveCoupon = () => {
    setCouponCode("");
    setCouponApplied(false);
    setAppliedCouponCode("");
    setdiscountdisplay(false);
    setDiscount("0");
  };

  function convertDateFormat(dateStr) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [day, month, year] = dateStr.split(" ");
    const monthNumber = monthNames.indexOf(month) + 1;
    const formattedMonth = monthNumber.toString().padStart(2, "0");
    const formattedDay = day.padStart(2, "0");
    const formattedDate = `${year}/${formattedMonth}/${formattedDay}`;

    return formattedDate;
  }

  return (
    <>
      <Navbar />
      {/* {loading && (
        <div className="loader">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      )} */}
      <>
        <div className=" flex max-w-full justify-between py-4 px-6 lg:px-20 ">
          <div className="col-lg-8 w-full cart-wrap">
            <button onClick={handleBack} className="backBtn ">
              <img
                className="rounded-full nav-profile-pic"
                src={BackButton}
                alt="ProfileImage"
              />
              Back
            </button>
            <h2>Your Cart</h2>
            <div className="sm:me-6 lg:me-0 mt-2 cart-item-wrapper">
              {cartItems.length === 0 ? (
                <p className="font-bold border border-red-300 h-12 w-full text-center pt-2 mt-3">
                  Your cart is empty.
                </p>
              ) : (
                <>
                  {processData(cartItems).map((item, index) => {
                    return (
                      <div key={index} className="cart-item">
                        <div className="cart-item-img">
                          <img
                            src={image}
                            alt={item.name}
                            onError={(e) => {
                              e.target.src = Defaultimage;
                            }}
                          />
                        </div>
                        <div className="cart-item-detail">
                          <div className="cart-top-content">
                            <h5>{item.name}</h5>
                            <p className="cart-item-time">
                              Time :{" "}
                              {formatTimeRangeView(item.time).replace(
                                "-",
                                " - "
                              )}
                            </p>
                            <p className="cart-item-time">
                              Scheduled on {convertDateFormat(item.date)}
                            </p>

                            <p className="cart-item-price">${item.rate}/hr</p>
                          </div>
                        </div>
                        <div className="cart-item-remove">
                          <button onClick={() => confirmRemove(item)}>
                            Remove
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  {cartItems.map((item, index) => {
                    return (
                      item.item_category !== "Services" &&
                      item.item_category === "Rental" && (
                        <div key={index} className="cart-item">
                          <div className="cart-item-img">
                            <img
                              src={item.image}
                              alt={item.name}
                              onError={(e) => {
                                e.target.src = Defaultimage;
                              }}
                            />
                          </div>
                          <div className="cart-item-detail">
                            <h5>{item.name}</h5>
                            {/* Scheduled on {item.date} */}
                            <p className="cart-item-qty">
                              Quantity: {item.quantity}
                            </p>
                            <p className="cart-item-price">
                              Price per item: ${item.price}/hr
                            </p>
                            <p className="cart-item-price">
                              Total Price: ${item.price * totalHr}
                            </p>
                          </div>
                          <div className="cart-item-remove">
                            <button onClick={() => confirmRemove(item)}>
                              Remove
                            </button>
                          </div>
                        </div>
                      )
                    );
                  })}{" "}
                  {cartItems.map((item, index) => {
                    return (
                      item.item_category !== "Services" &&
                      item.item_category !== "Rental" && (
                        <div key={index} className="cart-item">
                          <div className="cart-item-img">
                            <img
                              src={item.image}
                              alt={item.name}
                              onError={(e) => {
                                e.target.src = Defaultimage;
                              }}
                            />
                          </div>
                          <div className="cart-item-detail">
                            <h5>{item.name}</h5>
                            {/* Scheduled on {item.date} */}
                            <p className="cart-item-qty">
                              Quantity: {item.quantity}
                            </p>
                            <p className="cart-item-price">
                              Price: ${item.price}
                            </p>
                          </div>
                          <div className="cart-item-remove">
                            <button onClick={() => confirmRemove(item)}>
                              Remove
                            </button>
                          </div>
                        </div>
                      )
                    );
                  })}
                  <div className="total">
                    <h3>Total: ${calculateTotal()}</h3>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Render cart button for mobile view */}
          {isMobileView && (
            <Button onClick={toggleCart} className="cartBtn">
              <span>${calculateTotal()}</span>

              <span>
                <ShoppingCart />
                Proceed
              </span>
            </Button>
          )}
          {/* Render cart button */}

          <div
            className={`cart col-lg-4 lg:ml-8 ${
              isMobileView && isCartOpen ? "mobile-open" : ""
            }`}
          >
            <span className="close-popup" onClick={toggleCart}>
              <Close />
            </span>
            <div className="price-details sm:mt-4 lg:mt-24 px-4">
              <h4>ORDER SUMMARY</h4>
              <div className="">
                <div className="">
                  <div className="flex  items-center  w-full">
                    <Input
                      className="flex-grow text-lg h-12 placeholder:text-base sm:placeholder:text-sm mr-4"
                      placeholder="Enter coupon code"
                      value={couponCode}
                      onChange={handleCouponCodeChange}
                    />
                    {!couponApplied && (
                      <Button
                        className="px-6 text-lg h-12"
                        onClick={handleApplyCoupon}
                      >
                        Apply
                      </Button>
                    )}
                    {couponApplied && (
                      <Button
                        className="px-6 text-lg h-12"
                        onClick={handleRemoveCoupon}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </div>
                <div className="price-cal mt-4">
                  <div className="flex justify-between">
                    <p>SubTotal:</p>
                    <p>${calculateTotal()}</p>
                  </div>

                  {discountdisplay && (
                    <div className="summary-item flex justify-between">
                      <p>Discount:</p>
                      <p>${discount === 0 ? 0 : discount}</p>
                    </div>
                  )}

                  {taxData?.map((item) => (
                    <div
                      className="flex justify-between"
                      key={item.description}
                    >
                      <p>{item.description}:</p>
                      <p>${calculateTotalWithGST(item.rate).toFixed(2)}</p>
                    </div>
                  ))}

                  <hr className="my-4" />
                  <div className="flex justify-between">
                    <p className="total">Total</p>
                    <p className="total">${FinalTotalAmt()}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3 payment_button">
              <Button
                className={`paymentButton ${loading ? "bg-gray-400" : ""}`}
                onClick={!loading ? toggleCardPopup : null}
                disabled={loading}
              >
                Proceed with Payment
              </Button>
            </div>
          </div>
        </div>
        <div className=" flex flex-col max-w-full justify-between py-4 px-6 lg:px-20 cancellation-pol">
          <h3>
            Booking Information{" "}
            <span className="mt-1">Cancellation Policy</span>
          </h3>
          <p>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum."
          </p>
        </div>
      </>
      <Modal
        title="Confirm Remove Item"
        visible={isModalVisible}
        onOk={removeFromCart}
        onCancel={() => setIsModalVisible(false)}
        className="removeCartPopup"
      >
        <p>Are you sure you want to remove this item from the cart?</p>
      </Modal>

      <PhoneNumberModal
        isModalVisible={isphoneModalVisible}
        setIsModalVisible={setIsphoneModalVisible}
      />
      {showLoginPopup && <LogInPopup togglePopup={toggleLoginPopup} />}

      <CardSelectionPopup
        isOpen={showCardSelectionPopup}
        onRequestClose={toggleCardPopup}
        cards={cartItems}
        onSelect={handleCardSelect}
      />
    </>
  );
};

export default CartPage;
