import React, { useState, useEffect } from "react";
import { ArrowRight, Settings } from "lucide-react";
import { Link, useLocation, Navigate, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Logo from "../../assets/images/logoknox.png";
import GoogleLogo from "../../assets/images/google_logo.svg";
import {
  verifyEmail,
  sendOTP,
  verifyOTP,
  signUp,
  GLogin,
} from "../../handlers/authentication/api";
import { TextField } from "@material-ui/core";
import RightBanner from "./RightBanner";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
const SignUp = () => {
  const location = useLocation();


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
    }, 0);
  
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
  }, []);

  const userData = location.state ? location.state.formData || {} : {};
  console.log(userData, "userData");
  const [formData, setFormData] = useState({
    full_name: userData.full_name || "",
    email: userData.email || "",
    phone: userData.phone || "",
    otp: userData.otp || "",
    countrycode: "+65",
    verifiedEmail: userData.verifiedEmail || 0,
  });
  console.log("form state", formData);

  useEffect(() => {
    if (userData.verifiedEmail) {
      setEmailVerified(true);
    }
  }, [userData.verifiedEmail]);

  const [errors, setErrors] = useState({
    full_name: "",
    email: "",
    phone: "",
    otp: "",
  });
  const [emailVerified, setEmailVerified] = useState(false);
  const [sentotp, setSentOtp] = useState(false);
  const navigate = useNavigate();
  const [otpVerified, setOtpVerified] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSignIn, setSignIn] = useState(false);
  const [otpButtonDisabled, setOtpButtonDisabled] = useState(false);
  const [emailSent, setEmailSend] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false); // State to track toast visibility

  useEffect(() => {
    if (location.state) {
      const storedFormData = location.state.formData || {};
      console.log(storedFormData, "storedformdata");
      setFormData({
        email: storedFormData.email,
        full_name: storedFormData.full_name,
        countrycode: formData.countrycode,
      });
      setEmailVerified(true);
    } else {
      setFormData({ countrycode: "+65" });
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    let errorMessage = "";

    setErrors({ ...errors, [name]: "" });

    if (name === "phone") {
      if (value.length == 8) {
        errorMessage = "Phone number must be  8 digits.";
      }
    }
    setErrors({ ...errors, [name]: errorMessage });

    const isFullNameFilled =
      name === "fullName" ? value !== "" : formData.full_name !== "";
    const isEmailFilled =
      name === "email" ? value !== "" : formData.email !== "";
    const isPhoneFilled =
      name === "phone" ? value !== "" : formData.phone !== "";
    const isOtpFilled = name === "otp" ? value !== "" : formData.otp !== "";

    setFormValid(
      isFullNameFilled && isEmailFilled && isPhoneFilled && isOtpFilled
    );
  };

  const handleVerifyEmail = async () => {
    try {
      localStorage.setItem("signupFormData", JSON.stringify(formData));
      console.log("hlo sample");
      const res = await verifyEmail(formData.email, formData.full_name);
      console.log(res, "response test");
      if (res.message.status_code === 200) {
        setIsToastVisible(true);

        toast.success("Verification link sent successfully to your email.", {
          toastId: "notificationToast", // Set a specific toastId for this message
          autoClose: 3000, // Duration in milliseconds
          onClose: () => setIsToastVisible(false), // Callback when toast is closed
        });
        setEmailSend(true); // Set email verification status to true
      } else if (res.message.status_code === 409) {
        toast.warning("Email is already verified.");
      } else {
        toast.error(
          "Failed to send verification link. Please try again later."
        );
      }
    } catch (error) {
      console.error("Failed to send verification email:", error);
    }
  };

  const handleSendOTP = async () => {
    var a = 0;
    console.log();
    if (formData?.phone?.length == 8 && a === 0) {
      console.log(formData);
      console.log("sample otp", formData.countrycode);
      try {
        await sendOTP(`${formData.countrycode}${formData.phone}`);
        a = 1;
        setOtpSent(true);
        setSentOtp(true);
        toast.success("OTP Successfully Sent to your Registered Phone Number");
      } catch (error) {
        console.error("Failed to send OTP:", error);
      }
    } else {
      toast.warning("Please Type Your Mobile Number of Atleast 8 digits");
    }
  };

  const handleVerifyOTP = async () => {
    try {
      console.log(formData.countrycode);
      const res = await verifyOTP(
        `${formData.countrycode}${formData.phone}`,
        formData.otp
      );
      console.log(res);
      if (res.data.message.message === "Mobile Verified") {
        setOtpVerified(true);
      }
      setIsToastVisible(true);
      setOtpButtonDisabled(true); // Disable the OTP button after verification
      toast.success("OTP verified Successfully", {
        toastId: "notificationToast", // Set a specific toastId for this message
        autoClose: 3000, // Duration in milliseconds
        onClose: () => setIsToastVisible(false), // Callback when toast is closed
      });
    } catch (error) {
      setIsToastVisible(true);
      toast.warning("Invalid OTP", {
        toastId: "notificationToast2", // Set a specific toastId for this message
        autoClose: 3000, // Duration in milliseconds
        onClose: () => setIsToastVisible(false), // Callback when toast is closed
      });
      console.error("OTP verification failed:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    // Google Sign-in code here
  };
  const handleGoogleSignInSuccess = async (response) => {
    console.log(response);
    const decoded = jwtDecode(response.credential);

    console.log(decoded);

    const transformedObject = {
      data: {
        id: decoded.sub, // 'sub' from original is mapped to 'id'
        email: decoded.email,
        verified_email: decoded.email_verified, // 'email_verified' renamed to 'verified_email'
        name: decoded.name,
        given_name: decoded.given_name,
        family_name: decoded.family_name,
        picture: decoded.picture,
        hd: decoded.iss, // 'iss' from original is mapped to 'hd'
      },
      provider: "google", // Add static 'provider' key
    };

    console.log(transformedObject);

    const res = await GLogin(transformedObject);

    localStorage.setItem("token", res.data.token);
    setIsLoggedIn(true);
    toast.success("Successfully Logged In with Google");
  };

  const handleGoogleSignInError = (error) => {
    console.error("Error signing in with Google:", error);
    toast.error("Google Sign-In failed");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailVerified) {
      toast.warning("Please verify your Email");
    }

    if (otpVerified && emailVerified) {
      try {
        console.log(formData, "form data");

        const statusCode = await signUp(
          formData.email,
          `${formData.countrycode}${formData.phone}`,
          formData.full_name
        );
        console.log(statusCode);

        if (statusCode.data.message.status_code === 200) {
          localStorage.setItem("token", statusCode.data.data.login_token);

          toast.success("SignUp Success Please check email for set password");

          setIsLoggedIn(true);
        } else if (statusCode === 417) {
          console.log("hlo");
        } else if (statusCode === 409) {
          toast.warning("Already Registered");
        } else {
          toast.warning(
            "Failed to send verification link. Please try again later."
          );
        }
      } catch (error) {
        // toast.error(error);
        if (error.response.status === 417) {
          console.log("capture");
          setOtpSent(false);
          setOtpVerified(false);
          setOtpButtonDisabled(false);
          toast.error("mobile number already registered");
        } else {
          console.error("Failed to send verification email:", error);
        }
        // console.log("hlo",error.response.status);
        // console.log()
      }
    } else {
      toast.warning("Please Verify Email And Phone Number");
    }
  };

  useEffect(() => {
    localStorage.removeItem("signupFormData");
  }, []);

  if (isSignIn) {
    return <Navigate to="/my-account" />;
  }

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <section className="min-h-screen flex items-stretch justify-center signup">
        <div className="app-container">
          <div className="w-full signup-form-container p-10">
            <div className="signUpLogo mb-4">
              <img
                src={Logo}
                alt="Logo"
                className="top-0 left-0"
                onClick={handleRedirect}
              />
            </div>
            <div className="form-body xl:p-20">
              <h2 className="text-2xl font-bold leading-tight text-black">
                Sign Up
              </h2>
              <p className="mt-2 text-left create-account">
                Create an account to book an amazing experience.
              </p>
              <div className="mt-4 space-y-3">
                {/* Google Sign-in Button */}
                <GoogleLogin
                  onSuccess={handleGoogleSignInSuccess}
                  onError={handleGoogleSignInError}
                  render={(renderProps) => (
                    <button
                      type="button"
                      className="button relative inline-flex w-full items-center justify-center gap-3 rounded-md border googleSignup border-gray-800 px-3 py-2 font-semibold text-gray-700 transition-all duration-50 hover:bg-gray-100 hover:text-black focus:bg-gray-100 focus:text-black focus:outline-none text-sm"
                      // onClick={handleGoogleSignIn}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <img
                        src={GoogleLogo}
                        alt="Google Sign Up"
                        className="google_logo"
                        height="30px"
                        width="30px"
                      />
                      Continue with Google
                    </button>
                  )}
                />
              </div>
              {/* Email Sign-up Form */}
              <p className="text-center mt-4">
                <span className="text-gray-400 font-semibold uppercase inline-block">
                  or
                </span>
              </p>
              <form
                action="#"
                method="POST"
                className="w-full"
                onSubmit={handleSubmit}
              >
                {/* Full Name */}
                <div className="row">
                  <div className="signIn">
                    <input
                      className="SignInInput"
                      type="text"
                      placeholder=" "
                      id="full_name"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleChange}
                      disabled={emailVerified}
                    />
                    {errors.full_name && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.full_name}
                      </p>
                    )}
                    <label className="promo-label">Full Name</label>
                  </div>
                </div>
                {/* Email Address */}
                <div className="row flex-row">
                  <div className="col-md-8 signIn">
                    <input
                      className="SignInInput"
                      placeholder=" "
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      disabled={emailVerified}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.email}
                      </p>
                    )}
                    <label className="promo-label">Email</label>
                  </div>
                  {/* Verified Email Button */}
                  {emailVerified && (
                    <div className="col-md-4 ">
                      <button
                        type="button"
                        className="inline-block w-full text-white bg-green-500 px-4 verify_btn rounded-md font-semibold hover:bg-green-600 transition duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                        disabled
                      >
                        Verified
                      </button>
                    </div>
                  )}
                  {/* Email Verification Button */}
                  {!emailVerified && !emailSent && (
                    <div className="col-md-4  ">
                      <button
                        type="button"
                        onClick={handleVerifyEmail} // Call handleVerifyEmail function
                        className="inline-block w-full text-white bg-black px-4 verify_btn rounded-md font-semibold hover:bg-opacity-50 transition duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50"
                      >
                        Verify
                      </button>
                    </div>
                  )}
                  {/* Email Sent State */}
                  {emailSent && !emailVerified && (
                    <div className="col-md-4 ">
                      <button
                        type="button"
                        disabled
                        className="inline-block w-full text-white bg-gray-500 px-4 verify_btn rounded-md font-semibold hover:bg-opacity-50 transition duration-200 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50"
                      >
                        Email Sent
                      </button>
                    </div>
                  )}
                </div>
                {/* Phone Number */}
                <div className="flex flex-wrap py-3 ">
                  <div className="flex items-center  flex-grow w-full md:w-auto">
                    <select
                      id="countrycode"
                      name="countrycode"
                      value={formData.countrycode}
                      onChange={handleChange}
                      className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-full md:w-auto"
                      disabled={otpSent}
                    >
                      <option value="+65">+65</option>
                      {/* <option value="+91">+91</option> */}
                    </select>
                    <input
                      className="ml-3 py-2 pl-6 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-full md:w-auto"
                      placeholder="Phone"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      disabled={otpSent}
                      maxLength={8}
                    />
                  </div>
                  <div className="w-full md:w-auto py-4">
                    {!otpSent && (
                      <button
                        type="button"
                        onClick={handleSendOTP}
                        className="inline-block w-full lg:mr-4 text-white bg-black px-4 py-2 rounded-md font-semibold hover:bg-opacity-50 transition duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50"
                      >
                        Send OTP
                      </button>
                    )}
                    {otpSent && (
                      <button
                        type="button"
                        onClick={handleVerifyOTP}
                        className={`inline-block w-full lg:mr-4 text-white ${
                          otpVerified ? "bg-green-500" : "bg-black"
                        } px-4 py-2 rounded-md font-semibold hover:bg-opacity-50 transition duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50`}
                        disabled={otpButtonDisabled}
                      >
                        {otpVerified ? "Verified" : "Verify OTP"}
                      </button>
                    )}
                  </div>
                </div>

                {/* OTP Input */}
                <div className="row ">
                  <div className="signIn">
                    <input
                      className="SignInInput"
                      placeholder=" "
                      id="otp"
                      name="otp"
                      value={formData.otp}
                      onChange={handleChange}
                      readOnly={otpVerified}
                    />
                    {errors.otp && (
                      <p className="text-red-500 text-xs mt-1">{errors.otp}</p>
                    )}
                    <label className="promo-label">OTP</label>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="flex justify-center mt-4">
                  <button
                    id="create-account-button"
                    type="submit"
                    className={`inline-flex w-50 items-center justify-center rounded-md bg-#ED1C25 px-3.5 py-2.5 font-semibold leading-7 text-white redBtn hover:bg-black/80 ${
                      formValid ? "" : "cursor-not-allowed opacity-50"
                    }`}
                    disabled={!formValid}
                  >
                    Create Account <ArrowRight className="ml-2" size={25} />
                  </button>
                </div>
              </form>
              {/* Sign-in Link */}
              <div className="flex justify-center mt-4 text-center ">
                <p className="text-sm text-gray-600">
                  Already have an account?
                  <Link
                    to="/sign-in"
                    className="font-medium text-red-500 no-underline ml-3 hover:text-black"
                  >
                    Log In
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <RightBanner />
        </div>
      </section>
    </>
  );
};

export default SignUp;
